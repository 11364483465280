"use strict";
exports.__esModule = true;
exports.WebsiteLanguageOrderByInput = exports.FunnelStepOrderByInput = exports.ReportContentOrderByInput = exports.BenchmarkSegmentOrderByInput = exports.SurveyCategoryOrderByInput = exports.SurveyOrderByInput = exports.SurveyLanguageGroupOrderByInput = exports.PeriodUsageOrderByInput = exports.DataSourceLicensePeriodOrderByInput = exports.OrderItemOrderByInput = exports.OrderOrderByInput = exports.ModuleLicenseOrderByInput = exports.DataSourceLicenseOrderByInput = exports.DataSourceAccessOrderByInput = exports.CustomerAccessOrderByInput = exports.CustomerOrderByInput = exports.CustomerCategoryOrderByInput = exports.CustomerStatus = exports.CustomerPrivacyPolicyType = exports.DataSourceType = exports.SurveyType = exports.ReportDistributionTextResponseFormat = exports.ReportDistributionFrequency = exports.ReportDistributionDelivery = exports.PrivacySetting = exports.TrackPageViews = exports.JobStatus = exports.TaggedCommentType = exports.QuestionType = exports.QuestionSubType = exports.LogicalOperator = exports.LogoSource = exports.EmbedPosition = exports.SurveySetting = exports.WebsiteCheckType = exports.WebsiteCheckStatus = exports.LinkState = exports.UserStatus = exports.RoleType = exports.Mfa = exports.PeriodType = exports.PaymentType = exports.PaymentStatus = exports.OrderItemType = exports.DiscountType = exports.Module = exports.LicenseStatus = exports.LicenseInterval = exports.ApiKeyOrderByInput = exports.AddressOrderByInput = void 0;
exports.QuestionGroupOrderByInput = exports.OptionGroupOrderByInput = exports.LicenseOrderByInput = exports.ImageOrderByInput = exports.FunnelOrderByInput = exports.FilterDateRangeOrderByInput = exports.CxOrderByInput = exports.CustomThemeOrderByInput = exports.ZipOrderByInput = exports.MunicipalityOrderByInput = exports.RegionOrderByInput = exports.CountryOrderByInput = exports.ContentSettingsOrderByInput = exports.ChartSettingsOrderByInput = exports.BlockOrderByInput = exports.BlacklistWordOrderByInput = exports.ReportOrderByInput = exports.CustomerPrivacyPolicyOrderByInput = exports.DataSourceOrderByInput = exports.DataSourceUsageOrderByInput = exports.ClientPanelOrderByInput = exports.ReportDeliveryOrderByInput = exports.ReportDistributionOrderByInput = exports.FlaggedCommentOrderByInput = exports.FlaggedCommentMetaInputOrderByInput = exports.TaggedCommentOrderByInput = exports.OptionCategoryOrderByInput = exports.OptionOrderByInput = exports.TextTranslationOrderByInput = exports.SurveyContentOrderByInput = exports.SurveyContentDependencyOrderByInput = exports.QuestionOrderByInput = exports.QuestionCategoryOrderByInput = exports.AutoTaggingOrderByInput = exports.AutoTaggedCommentOrderByInput = exports.QuestionResponseOrderByInput = exports.SurveyResponseOrderByInput = exports.SharedFilterSegmentOrderByInput = exports.FilterOptionOrderByInput = exports.FilterSegmentOrderByInput = exports.WebsiteOrderByInput = exports.ScriptOrderByInput = exports.DeviceType = exports.WebDistributionOrderByInput = exports.CheckedLinkOrderByInput = exports.SettingOrderByInput = exports.RoleOrderByInput = exports.FilterOrderByInput = exports.FileOrderByInput = exports.WebsiteCheckOrderByInput = void 0;
exports.MutationType = exports.WhitelistWordOrderByInput = exports.UserOrderByInput = exports.TranslationOrderByInput = exports.TextOrderByInput = exports.SurveySettingsOrderByInput = exports.SegmentOrderByInput = exports.SegmentType = exports.ScoreResultsSettingsOrderByInput = exports.QuestionSettingsOrderByInput = void 0;
var AddressOrderByInput;
(function (AddressOrderByInput) {
    AddressOrderByInput["CityAsc"] = "city_ASC";
    AddressOrderByInput["CityDesc"] = "city_DESC";
    AddressOrderByInput["CountryCodeAsc"] = "countryCode_ASC";
    AddressOrderByInput["CountryCodeDesc"] = "countryCode_DESC";
    AddressOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    AddressOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    AddressOrderByInput["IdAsc"] = "id_ASC";
    AddressOrderByInput["IdDesc"] = "id_DESC";
    AddressOrderByInput["StreetAsc"] = "street_ASC";
    AddressOrderByInput["StreetDesc"] = "street_DESC";
    AddressOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    AddressOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    AddressOrderByInput["ZipAsc"] = "zip_ASC";
    AddressOrderByInput["ZipDesc"] = "zip_DESC";
})(AddressOrderByInput = exports.AddressOrderByInput || (exports.AddressOrderByInput = {}));
var ApiKeyOrderByInput;
(function (ApiKeyOrderByInput) {
    ApiKeyOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ApiKeyOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ApiKeyOrderByInput["IdAsc"] = "id_ASC";
    ApiKeyOrderByInput["IdDesc"] = "id_DESC";
    ApiKeyOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ApiKeyOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ApiKeyOrderByInput = exports.ApiKeyOrderByInput || (exports.ApiKeyOrderByInput = {}));
var LicenseInterval;
(function (LicenseInterval) {
    LicenseInterval["Monthly"] = "MONTHLY";
    LicenseInterval["Trial"] = "TRIAL";
    LicenseInterval["Yearly"] = "YEARLY";
})(LicenseInterval = exports.LicenseInterval || (exports.LicenseInterval = {}));
var LicenseStatus;
(function (LicenseStatus) {
    LicenseStatus["Active"] = "ACTIVE";
    LicenseStatus["Deprecated"] = "DEPRECATED";
    LicenseStatus["Inactive"] = "INACTIVE";
})(LicenseStatus = exports.LicenseStatus || (exports.LicenseStatus = {}));
var Module;
(function (Module) {
    Module["Addon"] = "ADDON";
    Module["Analytics"] = "ANALYTICS";
    Module["CxSurveys"] = "CX_SURVEYS";
    Module["Heatmaps"] = "HEATMAPS";
    Module["UxSurveys"] = "UX_SURVEYS";
})(Module = exports.Module || (exports.Module = {}));
var DiscountType;
(function (DiscountType) {
    DiscountType["Amount"] = "AMOUNT";
    DiscountType["Percentage"] = "PERCENTAGE";
})(DiscountType = exports.DiscountType || (exports.DiscountType = {}));
var OrderItemType;
(function (OrderItemType) {
    OrderItemType["Addon"] = "ADDON";
    OrderItemType["Info"] = "INFO";
    OrderItemType["License"] = "LICENSE";
    OrderItemType["Refund"] = "REFUND";
})(OrderItemType = exports.OrderItemType || (exports.OrderItemType = {}));
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Failed"] = "FAILED";
    PaymentStatus["Invoice"] = "INVOICE";
    PaymentStatus["Paid"] = "PAID";
    PaymentStatus["Pending"] = "PENDING";
    PaymentStatus["Refunded"] = "REFUNDED";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
var PaymentType;
(function (PaymentType) {
    PaymentType["Invoice"] = "INVOICE";
    PaymentType["Stripe"] = "STRIPE";
})(PaymentType = exports.PaymentType || (exports.PaymentType = {}));
var PeriodType;
(function (PeriodType) {
    PeriodType["Day"] = "DAY";
    PeriodType["Month"] = "MONTH";
    PeriodType["Quarter"] = "QUARTER";
    PeriodType["Week"] = "WEEK";
    PeriodType["Year"] = "YEAR";
})(PeriodType = exports.PeriodType || (exports.PeriodType = {}));
var Mfa;
(function (Mfa) {
    Mfa["Sms"] = "SMS";
})(Mfa = exports.Mfa || (exports.Mfa = {}));
var RoleType;
(function (RoleType) {
    RoleType["Customer"] = "CUSTOMER";
    RoleType["DataSource"] = "DATA_SOURCE";
    RoleType["Internal"] = "INTERNAL";
    RoleType["Partner"] = "PARTNER";
    RoleType["Website"] = "WEBSITE";
})(RoleType = exports.RoleType || (exports.RoleType = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus["Active"] = "ACTIVE";
    UserStatus["Blocked"] = "BLOCKED";
    UserStatus["Invited"] = "INVITED";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
var LinkState;
(function (LinkState) {
    LinkState["Broken"] = "BROKEN";
    LinkState["Ok"] = "OK";
    LinkState["Skipped"] = "SKIPPED";
})(LinkState = exports.LinkState || (exports.LinkState = {}));
var WebsiteCheckStatus;
(function (WebsiteCheckStatus) {
    WebsiteCheckStatus["Completed"] = "COMPLETED";
    WebsiteCheckStatus["Failed"] = "FAILED";
    WebsiteCheckStatus["Pending"] = "PENDING";
    WebsiteCheckStatus["Running"] = "RUNNING";
})(WebsiteCheckStatus = exports.WebsiteCheckStatus || (exports.WebsiteCheckStatus = {}));
var WebsiteCheckType;
(function (WebsiteCheckType) {
    WebsiteCheckType["Manual"] = "MANUAL";
    WebsiteCheckType["Scheduled"] = "SCHEDULED";
})(WebsiteCheckType = exports.WebsiteCheckType || (exports.WebsiteCheckType = {}));
var SurveySetting;
(function (SurveySetting) {
    SurveySetting["Custom"] = "CUSTOM";
    SurveySetting["Default"] = "DEFAULT";
    SurveySetting["Desktop"] = "DESKTOP";
    SurveySetting["Disabled"] = "DISABLED";
    SurveySetting["Mobile"] = "MOBILE";
    SurveySetting["Primary"] = "PRIMARY";
    SurveySetting["Tablet"] = "TABLET";
})(SurveySetting = exports.SurveySetting || (exports.SurveySetting = {}));
var EmbedPosition;
(function (EmbedPosition) {
    EmbedPosition["After"] = "AFTER";
    EmbedPosition["Before"] = "BEFORE";
    EmbedPosition["FirstChild"] = "FIRST_CHILD";
    EmbedPosition["LastChild"] = "LAST_CHILD";
})(EmbedPosition = exports.EmbedPosition || (exports.EmbedPosition = {}));
var LogoSource;
(function (LogoSource) {
    LogoSource["Customer"] = "CUSTOMER";
    LogoSource["None"] = "NONE";
    LogoSource["Url"] = "URL";
})(LogoSource = exports.LogoSource || (exports.LogoSource = {}));
var LogicalOperator;
(function (LogicalOperator) {
    LogicalOperator["And"] = "AND";
    LogicalOperator["Or"] = "OR";
})(LogicalOperator = exports.LogicalOperator || (exports.LogicalOperator = {}));
var QuestionSubType;
(function (QuestionSubType) {
    QuestionSubType["Email"] = "EMAIL";
    QuestionSubType["Number"] = "NUMBER";
    QuestionSubType["Text"] = "TEXT";
    QuestionSubType["Textarea"] = "TEXTAREA";
})(QuestionSubType = exports.QuestionSubType || (exports.QuestionSubType = {}));
var QuestionType;
(function (QuestionType) {
    QuestionType["Checkbox"] = "CHECKBOX";
    QuestionType["Contact"] = "CONTACT";
    QuestionType["Country"] = "COUNTRY";
    QuestionType["Field"] = "FIELD";
    QuestionType["Find"] = "FIND";
    QuestionType["Grade"] = "GRADE";
    QuestionType["Nps"] = "NPS";
    QuestionType["Numbers"] = "NUMBERS";
    QuestionType["Radio"] = "RADIO";
    QuestionType["Sentiment"] = "SENTIMENT";
    QuestionType["Thumbs"] = "THUMBS";
    QuestionType["Zip"] = "ZIP";
})(QuestionType = exports.QuestionType || (exports.QuestionType = {}));
var TaggedCommentType;
(function (TaggedCommentType) {
    TaggedCommentType["Sentiment"] = "SENTIMENT";
    TaggedCommentType["Theme"] = "THEME";
    TaggedCommentType["Wordcloud"] = "WORDCLOUD";
})(TaggedCommentType = exports.TaggedCommentType || (exports.TaggedCommentType = {}));
var JobStatus;
(function (JobStatus) {
    JobStatus["Completed"] = "COMPLETED";
    JobStatus["Failed"] = "FAILED";
    JobStatus["Pending"] = "PENDING";
    JobStatus["Running"] = "RUNNING";
})(JobStatus = exports.JobStatus || (exports.JobStatus = {}));
var TrackPageViews;
(function (TrackPageViews) {
    TrackPageViews["Never"] = "NEVER";
    TrackPageViews["PageLoad"] = "PAGE_LOAD";
    TrackPageViews["UrlChange"] = "URL_CHANGE";
})(TrackPageViews = exports.TrackPageViews || (exports.TrackPageViews = {}));
var PrivacySetting;
(function (PrivacySetting) {
    PrivacySetting["Always"] = "ALWAYS";
    PrivacySetting["Never"] = "NEVER";
    PrivacySetting["WithConsent"] = "WITH_CONSENT";
})(PrivacySetting = exports.PrivacySetting || (exports.PrivacySetting = {}));
var ReportDistributionDelivery;
(function (ReportDistributionDelivery) {
    ReportDistributionDelivery["Email"] = "EMAIL";
})(ReportDistributionDelivery = exports.ReportDistributionDelivery || (exports.ReportDistributionDelivery = {}));
var ReportDistributionFrequency;
(function (ReportDistributionFrequency) {
    ReportDistributionFrequency["Daily"] = "DAILY";
    ReportDistributionFrequency["Monthly"] = "MONTHLY";
    ReportDistributionFrequency["Quarterly"] = "QUARTERLY";
    ReportDistributionFrequency["Weekly"] = "WEEKLY";
    ReportDistributionFrequency["Yearly"] = "YEARLY";
})(ReportDistributionFrequency = exports.ReportDistributionFrequency || (exports.ReportDistributionFrequency = {}));
var ReportDistributionTextResponseFormat;
(function (ReportDistributionTextResponseFormat) {
    ReportDistributionTextResponseFormat["Pdf"] = "PDF";
    ReportDistributionTextResponseFormat["Xls"] = "XLS";
})(ReportDistributionTextResponseFormat = exports.ReportDistributionTextResponseFormat || (exports.ReportDistributionTextResponseFormat = {}));
var SurveyType;
(function (SurveyType) {
    SurveyType["Cx"] = "CX";
    SurveyType["Feedback"] = "FEEDBACK";
    SurveyType["General"] = "GENERAL";
    SurveyType["Targeted"] = "TARGETED";
})(SurveyType = exports.SurveyType || (exports.SurveyType = {}));
var DataSourceType;
(function (DataSourceType) {
    DataSourceType["Cx"] = "CX";
    DataSourceType["Website"] = "WEBSITE";
})(DataSourceType = exports.DataSourceType || (exports.DataSourceType = {}));
var CustomerPrivacyPolicyType;
(function (CustomerPrivacyPolicyType) {
    CustomerPrivacyPolicyType["Inline"] = "INLINE";
    CustomerPrivacyPolicyType["Url"] = "URL";
})(CustomerPrivacyPolicyType = exports.CustomerPrivacyPolicyType || (exports.CustomerPrivacyPolicyType = {}));
var CustomerStatus;
(function (CustomerStatus) {
    CustomerStatus["Active"] = "ACTIVE";
    CustomerStatus["Archived"] = "ARCHIVED";
    CustomerStatus["Blocked"] = "BLOCKED";
    CustomerStatus["Pending"] = "PENDING";
})(CustomerStatus = exports.CustomerStatus || (exports.CustomerStatus = {}));
var CustomerCategoryOrderByInput;
(function (CustomerCategoryOrderByInput) {
    CustomerCategoryOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    CustomerCategoryOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    CustomerCategoryOrderByInput["IdAsc"] = "id_ASC";
    CustomerCategoryOrderByInput["IdDesc"] = "id_DESC";
    CustomerCategoryOrderByInput["LegacyIdAsc"] = "legacyId_ASC";
    CustomerCategoryOrderByInput["LegacyIdDesc"] = "legacyId_DESC";
    CustomerCategoryOrderByInput["TitleAsc"] = "title_ASC";
    CustomerCategoryOrderByInput["TitleDesc"] = "title_DESC";
    CustomerCategoryOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    CustomerCategoryOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(CustomerCategoryOrderByInput = exports.CustomerCategoryOrderByInput || (exports.CustomerCategoryOrderByInput = {}));
var CustomerOrderByInput;
(function (CustomerOrderByInput) {
    CustomerOrderByInput["AliasAsc"] = "alias_ASC";
    CustomerOrderByInput["AliasDesc"] = "alias_DESC";
    CustomerOrderByInput["AllowPersonalDataAsc"] = "allowPersonalData_ASC";
    CustomerOrderByInput["AllowPersonalDataDesc"] = "allowPersonalData_DESC";
    CustomerOrderByInput["BillingCommentAsc"] = "billingComment_ASC";
    CustomerOrderByInput["BillingCommentDesc"] = "billingComment_DESC";
    CustomerOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    CustomerOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    CustomerOrderByInput["IdAsc"] = "id_ASC";
    CustomerOrderByInput["IdDesc"] = "id_DESC";
    CustomerOrderByInput["LastUserActivityAlertAsc"] = "lastUserActivityAlert_ASC";
    CustomerOrderByInput["LastUserActivityAlertDesc"] = "lastUserActivityAlert_DESC";
    CustomerOrderByInput["LegacyIdAsc"] = "legacyId_ASC";
    CustomerOrderByInput["LegacyIdDesc"] = "legacyId_DESC";
    CustomerOrderByInput["NameAsc"] = "name_ASC";
    CustomerOrderByInput["NameDesc"] = "name_DESC";
    CustomerOrderByInput["PaymentTypeAsc"] = "paymentType_ASC";
    CustomerOrderByInput["PaymentTypeDesc"] = "paymentType_DESC";
    CustomerOrderByInput["PersonalDataControllerAsc"] = "personalDataController_ASC";
    CustomerOrderByInput["PersonalDataControllerDesc"] = "personalDataController_DESC";
    CustomerOrderByInput["StatusAsc"] = "status_ASC";
    CustomerOrderByInput["StatusDesc"] = "status_DESC";
    CustomerOrderByInput["StripeCustomerIdAsc"] = "stripeCustomerId_ASC";
    CustomerOrderByInput["StripeCustomerIdDesc"] = "stripeCustomerId_DESC";
    CustomerOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    CustomerOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    CustomerOrderByInput["VatAsc"] = "vat_ASC";
    CustomerOrderByInput["VatDesc"] = "vat_DESC";
})(CustomerOrderByInput = exports.CustomerOrderByInput || (exports.CustomerOrderByInput = {}));
var CustomerAccessOrderByInput;
(function (CustomerAccessOrderByInput) {
    CustomerAccessOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    CustomerAccessOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    CustomerAccessOrderByInput["IdAsc"] = "id_ASC";
    CustomerAccessOrderByInput["IdDesc"] = "id_DESC";
    CustomerAccessOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    CustomerAccessOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(CustomerAccessOrderByInput = exports.CustomerAccessOrderByInput || (exports.CustomerAccessOrderByInput = {}));
var DataSourceAccessOrderByInput;
(function (DataSourceAccessOrderByInput) {
    DataSourceAccessOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    DataSourceAccessOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    DataSourceAccessOrderByInput["IdAsc"] = "id_ASC";
    DataSourceAccessOrderByInput["IdDesc"] = "id_DESC";
    DataSourceAccessOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    DataSourceAccessOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(DataSourceAccessOrderByInput = exports.DataSourceAccessOrderByInput || (exports.DataSourceAccessOrderByInput = {}));
var DataSourceLicenseOrderByInput;
(function (DataSourceLicenseOrderByInput) {
    DataSourceLicenseOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    DataSourceLicenseOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    DataSourceLicenseOrderByInput["EndDateAsc"] = "endDate_ASC";
    DataSourceLicenseOrderByInput["EndDateDesc"] = "endDate_DESC";
    DataSourceLicenseOrderByInput["IdAsc"] = "id_ASC";
    DataSourceLicenseOrderByInput["IdDesc"] = "id_DESC";
    DataSourceLicenseOrderByInput["IntervalAsc"] = "interval_ASC";
    DataSourceLicenseOrderByInput["IntervalDesc"] = "interval_DESC";
    DataSourceLicenseOrderByInput["RenewalDateAsc"] = "renewalDate_ASC";
    DataSourceLicenseOrderByInput["RenewalDateDesc"] = "renewalDate_DESC";
    DataSourceLicenseOrderByInput["StartDateAsc"] = "startDate_ASC";
    DataSourceLicenseOrderByInput["StartDateDesc"] = "startDate_DESC";
    DataSourceLicenseOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    DataSourceLicenseOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(DataSourceLicenseOrderByInput = exports.DataSourceLicenseOrderByInput || (exports.DataSourceLicenseOrderByInput = {}));
var ModuleLicenseOrderByInput;
(function (ModuleLicenseOrderByInput) {
    ModuleLicenseOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ModuleLicenseOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ModuleLicenseOrderByInput["IdAsc"] = "id_ASC";
    ModuleLicenseOrderByInput["IdDesc"] = "id_DESC";
    ModuleLicenseOrderByInput["LevelAsc"] = "level_ASC";
    ModuleLicenseOrderByInput["LevelDesc"] = "level_DESC";
    ModuleLicenseOrderByInput["ModuleAsc"] = "module_ASC";
    ModuleLicenseOrderByInput["ModuleDesc"] = "module_DESC";
    ModuleLicenseOrderByInput["StatusAsc"] = "status_ASC";
    ModuleLicenseOrderByInput["StatusDesc"] = "status_DESC";
    ModuleLicenseOrderByInput["TitleAsc"] = "title_ASC";
    ModuleLicenseOrderByInput["TitleDesc"] = "title_DESC";
    ModuleLicenseOrderByInput["UniqueTitleAsc"] = "uniqueTitle_ASC";
    ModuleLicenseOrderByInput["UniqueTitleDesc"] = "uniqueTitle_DESC";
    ModuleLicenseOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ModuleLicenseOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ModuleLicenseOrderByInput = exports.ModuleLicenseOrderByInput || (exports.ModuleLicenseOrderByInput = {}));
var OrderOrderByInput;
(function (OrderOrderByInput) {
    OrderOrderByInput["AmountAsc"] = "amount_ASC";
    OrderOrderByInput["AmountDesc"] = "amount_DESC";
    OrderOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    OrderOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    OrderOrderByInput["CurrencyAsc"] = "currency_ASC";
    OrderOrderByInput["CurrencyDesc"] = "currency_DESC";
    OrderOrderByInput["DataSourceAsc"] = "dataSource_ASC";
    OrderOrderByInput["DataSourceDesc"] = "dataSource_DESC";
    OrderOrderByInput["DiscountAsc"] = "discount_ASC";
    OrderOrderByInput["DiscountDesc"] = "discount_DESC";
    OrderOrderByInput["DiscountTypeAsc"] = "discountType_ASC";
    OrderOrderByInput["DiscountTypeDesc"] = "discountType_DESC";
    OrderOrderByInput["IdAsc"] = "id_ASC";
    OrderOrderByInput["IdDesc"] = "id_DESC";
    OrderOrderByInput["MonthlyAmountAsc"] = "monthlyAmount_ASC";
    OrderOrderByInput["MonthlyAmountDesc"] = "monthlyAmount_DESC";
    OrderOrderByInput["OrderNumberAsc"] = "orderNumber_ASC";
    OrderOrderByInput["OrderNumberDesc"] = "orderNumber_DESC";
    OrderOrderByInput["PaymentDateAsc"] = "paymentDate_ASC";
    OrderOrderByInput["PaymentDateDesc"] = "paymentDate_DESC";
    OrderOrderByInput["PaymentStatusAsc"] = "paymentStatus_ASC";
    OrderOrderByInput["PaymentStatusDesc"] = "paymentStatus_DESC";
    OrderOrderByInput["PaymentTypeAsc"] = "paymentType_ASC";
    OrderOrderByInput["PaymentTypeDesc"] = "paymentType_DESC";
    OrderOrderByInput["StripeInvoiceIdAsc"] = "stripeInvoiceId_ASC";
    OrderOrderByInput["StripeInvoiceIdDesc"] = "stripeInvoiceId_DESC";
    OrderOrderByInput["StripePaymentIntentIdAsc"] = "stripePaymentIntentId_ASC";
    OrderOrderByInput["StripePaymentIntentIdDesc"] = "stripePaymentIntentId_DESC";
    OrderOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    OrderOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(OrderOrderByInput = exports.OrderOrderByInput || (exports.OrderOrderByInput = {}));
var OrderItemOrderByInput;
(function (OrderItemOrderByInput) {
    OrderItemOrderByInput["AmountAsc"] = "amount_ASC";
    OrderItemOrderByInput["AmountDesc"] = "amount_DESC";
    OrderItemOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    OrderItemOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    OrderItemOrderByInput["DescriptionAsc"] = "description_ASC";
    OrderItemOrderByInput["DescriptionDesc"] = "description_DESC";
    OrderItemOrderByInput["EndDateAsc"] = "endDate_ASC";
    OrderItemOrderByInput["EndDateDesc"] = "endDate_DESC";
    OrderItemOrderByInput["IdAsc"] = "id_ASC";
    OrderItemOrderByInput["IdDesc"] = "id_DESC";
    OrderItemOrderByInput["LimitAsc"] = "limit_ASC";
    OrderItemOrderByInput["LimitDesc"] = "limit_DESC";
    OrderItemOrderByInput["QuantityAsc"] = "quantity_ASC";
    OrderItemOrderByInput["QuantityDesc"] = "quantity_DESC";
    OrderItemOrderByInput["RelationIdAsc"] = "relationId_ASC";
    OrderItemOrderByInput["RelationIdDesc"] = "relationId_DESC";
    OrderItemOrderByInput["StartDateAsc"] = "startDate_ASC";
    OrderItemOrderByInput["StartDateDesc"] = "startDate_DESC";
    OrderItemOrderByInput["TitleAsc"] = "title_ASC";
    OrderItemOrderByInput["TitleDesc"] = "title_DESC";
    OrderItemOrderByInput["TypeAsc"] = "type_ASC";
    OrderItemOrderByInput["TypeDesc"] = "type_DESC";
    OrderItemOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    OrderItemOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(OrderItemOrderByInput = exports.OrderItemOrderByInput || (exports.OrderItemOrderByInput = {}));
var DataSourceLicensePeriodOrderByInput;
(function (DataSourceLicensePeriodOrderByInput) {
    DataSourceLicensePeriodOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    DataSourceLicensePeriodOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    DataSourceLicensePeriodOrderByInput["EndDateAsc"] = "endDate_ASC";
    DataSourceLicensePeriodOrderByInput["EndDateDesc"] = "endDate_DESC";
    DataSourceLicensePeriodOrderByInput["IdAsc"] = "id_ASC";
    DataSourceLicensePeriodOrderByInput["IdDesc"] = "id_DESC";
    DataSourceLicensePeriodOrderByInput["StartDateAsc"] = "startDate_ASC";
    DataSourceLicensePeriodOrderByInput["StartDateDesc"] = "startDate_DESC";
    DataSourceLicensePeriodOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    DataSourceLicensePeriodOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(DataSourceLicensePeriodOrderByInput = exports.DataSourceLicensePeriodOrderByInput || (exports.DataSourceLicensePeriodOrderByInput = {}));
var PeriodUsageOrderByInput;
(function (PeriodUsageOrderByInput) {
    PeriodUsageOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    PeriodUsageOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    PeriodUsageOrderByInput["CurrentAsc"] = "current_ASC";
    PeriodUsageOrderByInput["CurrentDesc"] = "current_DESC";
    PeriodUsageOrderByInput["EstimatedAsc"] = "estimated_ASC";
    PeriodUsageOrderByInput["EstimatedDesc"] = "estimated_DESC";
    PeriodUsageOrderByInput["IdAsc"] = "id_ASC";
    PeriodUsageOrderByInput["IdDesc"] = "id_DESC";
    PeriodUsageOrderByInput["KeyAsc"] = "key_ASC";
    PeriodUsageOrderByInput["KeyDesc"] = "key_DESC";
    PeriodUsageOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    PeriodUsageOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(PeriodUsageOrderByInput = exports.PeriodUsageOrderByInput || (exports.PeriodUsageOrderByInput = {}));
var SurveyLanguageGroupOrderByInput;
(function (SurveyLanguageGroupOrderByInput) {
    SurveyLanguageGroupOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    SurveyLanguageGroupOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    SurveyLanguageGroupOrderByInput["IdAsc"] = "id_ASC";
    SurveyLanguageGroupOrderByInput["IdDesc"] = "id_DESC";
    SurveyLanguageGroupOrderByInput["TitleAsc"] = "title_ASC";
    SurveyLanguageGroupOrderByInput["TitleDesc"] = "title_DESC";
    SurveyLanguageGroupOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    SurveyLanguageGroupOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(SurveyLanguageGroupOrderByInput = exports.SurveyLanguageGroupOrderByInput || (exports.SurveyLanguageGroupOrderByInput = {}));
var SurveyOrderByInput;
(function (SurveyOrderByInput) {
    SurveyOrderByInput["AllowPersonalDataAsc"] = "allowPersonalData_ASC";
    SurveyOrderByInput["AllowPersonalDataDesc"] = "allowPersonalData_DESC";
    SurveyOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    SurveyOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    SurveyOrderByInput["CustomerAliasAsc"] = "customerAlias_ASC";
    SurveyOrderByInput["CustomerAliasDesc"] = "customerAlias_DESC";
    SurveyOrderByInput["IdAsc"] = "id_ASC";
    SurveyOrderByInput["IdDesc"] = "id_DESC";
    SurveyOrderByInput["LanguageAsc"] = "language_ASC";
    SurveyOrderByInput["LanguageDesc"] = "language_DESC";
    SurveyOrderByInput["LegacyIdAsc"] = "legacyId_ASC";
    SurveyOrderByInput["LegacyIdDesc"] = "legacyId_DESC";
    SurveyOrderByInput["PrimaryAsc"] = "primary_ASC";
    SurveyOrderByInput["PrimaryDesc"] = "primary_DESC";
    SurveyOrderByInput["TitleAsc"] = "title_ASC";
    SurveyOrderByInput["TitleDesc"] = "title_DESC";
    SurveyOrderByInput["TypeAsc"] = "type_ASC";
    SurveyOrderByInput["TypeDesc"] = "type_DESC";
    SurveyOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    SurveyOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    SurveyOrderByInput["VersionAsc"] = "version_ASC";
    SurveyOrderByInput["VersionDesc"] = "version_DESC";
})(SurveyOrderByInput = exports.SurveyOrderByInput || (exports.SurveyOrderByInput = {}));
var SurveyCategoryOrderByInput;
(function (SurveyCategoryOrderByInput) {
    SurveyCategoryOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    SurveyCategoryOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    SurveyCategoryOrderByInput["IdAsc"] = "id_ASC";
    SurveyCategoryOrderByInput["IdDesc"] = "id_DESC";
    SurveyCategoryOrderByInput["LegacyIdAsc"] = "legacyId_ASC";
    SurveyCategoryOrderByInput["LegacyIdDesc"] = "legacyId_DESC";
    SurveyCategoryOrderByInput["TitleAsc"] = "title_ASC";
    SurveyCategoryOrderByInput["TitleDesc"] = "title_DESC";
    SurveyCategoryOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    SurveyCategoryOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(SurveyCategoryOrderByInput = exports.SurveyCategoryOrderByInput || (exports.SurveyCategoryOrderByInput = {}));
var BenchmarkSegmentOrderByInput;
(function (BenchmarkSegmentOrderByInput) {
    BenchmarkSegmentOrderByInput["ColorAsc"] = "color_ASC";
    BenchmarkSegmentOrderByInput["ColorDesc"] = "color_DESC";
    BenchmarkSegmentOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    BenchmarkSegmentOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    BenchmarkSegmentOrderByInput["IdAsc"] = "id_ASC";
    BenchmarkSegmentOrderByInput["IdDesc"] = "id_DESC";
    BenchmarkSegmentOrderByInput["IsEnabledAsc"] = "isEnabled_ASC";
    BenchmarkSegmentOrderByInput["IsEnabledDesc"] = "isEnabled_DESC";
    BenchmarkSegmentOrderByInput["SortIndexAsc"] = "sortIndex_ASC";
    BenchmarkSegmentOrderByInput["SortIndexDesc"] = "sortIndex_DESC";
    BenchmarkSegmentOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    BenchmarkSegmentOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(BenchmarkSegmentOrderByInput = exports.BenchmarkSegmentOrderByInput || (exports.BenchmarkSegmentOrderByInput = {}));
var ReportContentOrderByInput;
(function (ReportContentOrderByInput) {
    ReportContentOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ReportContentOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ReportContentOrderByInput["IdAsc"] = "id_ASC";
    ReportContentOrderByInput["IdDesc"] = "id_DESC";
    ReportContentOrderByInput["SortOrderAsc"] = "sortOrder_ASC";
    ReportContentOrderByInput["SortOrderDesc"] = "sortOrder_DESC";
    ReportContentOrderByInput["TitleAsc"] = "title_ASC";
    ReportContentOrderByInput["TitleDesc"] = "title_DESC";
    ReportContentOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ReportContentOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ReportContentOrderByInput = exports.ReportContentOrderByInput || (exports.ReportContentOrderByInput = {}));
var FunnelStepOrderByInput;
(function (FunnelStepOrderByInput) {
    FunnelStepOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    FunnelStepOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    FunnelStepOrderByInput["IdAsc"] = "id_ASC";
    FunnelStepOrderByInput["IdDesc"] = "id_DESC";
    FunnelStepOrderByInput["SortIndexAsc"] = "sortIndex_ASC";
    FunnelStepOrderByInput["SortIndexDesc"] = "sortIndex_DESC";
    FunnelStepOrderByInput["TitleAsc"] = "title_ASC";
    FunnelStepOrderByInput["TitleDesc"] = "title_DESC";
    FunnelStepOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    FunnelStepOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(FunnelStepOrderByInput = exports.FunnelStepOrderByInput || (exports.FunnelStepOrderByInput = {}));
var WebsiteLanguageOrderByInput;
(function (WebsiteLanguageOrderByInput) {
    WebsiteLanguageOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    WebsiteLanguageOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    WebsiteLanguageOrderByInput["IdAsc"] = "id_ASC";
    WebsiteLanguageOrderByInput["IdDesc"] = "id_DESC";
    WebsiteLanguageOrderByInput["LanguageAsc"] = "language_ASC";
    WebsiteLanguageOrderByInput["LanguageDesc"] = "language_DESC";
    WebsiteLanguageOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    WebsiteLanguageOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    WebsiteLanguageOrderByInput["UrlAsc"] = "url_ASC";
    WebsiteLanguageOrderByInput["UrlDesc"] = "url_DESC";
})(WebsiteLanguageOrderByInput = exports.WebsiteLanguageOrderByInput || (exports.WebsiteLanguageOrderByInput = {}));
var WebsiteCheckOrderByInput;
(function (WebsiteCheckOrderByInput) {
    WebsiteCheckOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    WebsiteCheckOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    WebsiteCheckOrderByInput["EntryUrlAsc"] = "entryUrl_ASC";
    WebsiteCheckOrderByInput["EntryUrlDesc"] = "entryUrl_DESC";
    WebsiteCheckOrderByInput["IdAsc"] = "id_ASC";
    WebsiteCheckOrderByInput["IdDesc"] = "id_DESC";
    WebsiteCheckOrderByInput["RecursiveAsc"] = "recursive_ASC";
    WebsiteCheckOrderByInput["RecursiveDesc"] = "recursive_DESC";
    WebsiteCheckOrderByInput["StatusAsc"] = "status_ASC";
    WebsiteCheckOrderByInput["StatusDesc"] = "status_DESC";
    WebsiteCheckOrderByInput["TypeAsc"] = "type_ASC";
    WebsiteCheckOrderByInput["TypeDesc"] = "type_DESC";
    WebsiteCheckOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    WebsiteCheckOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(WebsiteCheckOrderByInput = exports.WebsiteCheckOrderByInput || (exports.WebsiteCheckOrderByInput = {}));
var FileOrderByInput;
(function (FileOrderByInput) {
    FileOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    FileOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    FileOrderByInput["FolderAsc"] = "folder_ASC";
    FileOrderByInput["FolderDesc"] = "folder_DESC";
    FileOrderByInput["FormatAsc"] = "format_ASC";
    FileOrderByInput["FormatDesc"] = "format_DESC";
    FileOrderByInput["HeightAsc"] = "height_ASC";
    FileOrderByInput["HeightDesc"] = "height_DESC";
    FileOrderByInput["IdAsc"] = "id_ASC";
    FileOrderByInput["IdDesc"] = "id_DESC";
    FileOrderByInput["LengthAsc"] = "length_ASC";
    FileOrderByInput["LengthDesc"] = "length_DESC";
    FileOrderByInput["S3KeyAsc"] = "s3Key_ASC";
    FileOrderByInput["S3KeyDesc"] = "s3Key_DESC";
    FileOrderByInput["SizeAsc"] = "size_ASC";
    FileOrderByInput["SizeDesc"] = "size_DESC";
    FileOrderByInput["TitleAsc"] = "title_ASC";
    FileOrderByInput["TitleDesc"] = "title_DESC";
    FileOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    FileOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    FileOrderByInput["WidthAsc"] = "width_ASC";
    FileOrderByInput["WidthDesc"] = "width_DESC";
})(FileOrderByInput = exports.FileOrderByInput || (exports.FileOrderByInput = {}));
var FilterOrderByInput;
(function (FilterOrderByInput) {
    FilterOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    FilterOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    FilterOrderByInput["DisplayedSurveyIdAsc"] = "displayedSurveyId_ASC";
    FilterOrderByInput["DisplayedSurveyIdDesc"] = "displayedSurveyId_DESC";
    FilterOrderByInput["IdAsc"] = "id_ASC";
    FilterOrderByInput["IdDesc"] = "id_DESC";
    FilterOrderByInput["SurveyGroupIdAsc"] = "surveyGroupId_ASC";
    FilterOrderByInput["SurveyGroupIdDesc"] = "surveyGroupId_DESC";
    FilterOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    FilterOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(FilterOrderByInput = exports.FilterOrderByInput || (exports.FilterOrderByInput = {}));
var RoleOrderByInput;
(function (RoleOrderByInput) {
    RoleOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    RoleOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    RoleOrderByInput["DescriptionAsc"] = "description_ASC";
    RoleOrderByInput["DescriptionDesc"] = "description_DESC";
    RoleOrderByInput["IdAsc"] = "id_ASC";
    RoleOrderByInput["IdDesc"] = "id_DESC";
    RoleOrderByInput["NameAsc"] = "name_ASC";
    RoleOrderByInput["NameDesc"] = "name_DESC";
    RoleOrderByInput["RoleTypeAsc"] = "roleType_ASC";
    RoleOrderByInput["RoleTypeDesc"] = "roleType_DESC";
    RoleOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    RoleOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    RoleOrderByInput["VisibleAsc"] = "visible_ASC";
    RoleOrderByInput["VisibleDesc"] = "visible_DESC";
})(RoleOrderByInput = exports.RoleOrderByInput || (exports.RoleOrderByInput = {}));
var SettingOrderByInput;
(function (SettingOrderByInput) {
    SettingOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    SettingOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    SettingOrderByInput["IdAsc"] = "id_ASC";
    SettingOrderByInput["IdDesc"] = "id_DESC";
    SettingOrderByInput["KeyAsc"] = "key_ASC";
    SettingOrderByInput["KeyDesc"] = "key_DESC";
    SettingOrderByInput["RelationIdAsc"] = "relationId_ASC";
    SettingOrderByInput["RelationIdDesc"] = "relationId_DESC";
    SettingOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    SettingOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    SettingOrderByInput["ValueAsc"] = "value_ASC";
    SettingOrderByInput["ValueDesc"] = "value_DESC";
})(SettingOrderByInput = exports.SettingOrderByInput || (exports.SettingOrderByInput = {}));
var CheckedLinkOrderByInput;
(function (CheckedLinkOrderByInput) {
    CheckedLinkOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    CheckedLinkOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    CheckedLinkOrderByInput["IdAsc"] = "id_ASC";
    CheckedLinkOrderByInput["IdDesc"] = "id_DESC";
    CheckedLinkOrderByInput["ParentAsc"] = "parent_ASC";
    CheckedLinkOrderByInput["ParentDesc"] = "parent_DESC";
    CheckedLinkOrderByInput["StateAsc"] = "state_ASC";
    CheckedLinkOrderByInput["StateDesc"] = "state_DESC";
    CheckedLinkOrderByInput["StatusAsc"] = "status_ASC";
    CheckedLinkOrderByInput["StatusDesc"] = "status_DESC";
    CheckedLinkOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    CheckedLinkOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    CheckedLinkOrderByInput["UrlAsc"] = "url_ASC";
    CheckedLinkOrderByInput["UrlDesc"] = "url_DESC";
})(CheckedLinkOrderByInput = exports.CheckedLinkOrderByInput || (exports.CheckedLinkOrderByInput = {}));
var WebDistributionOrderByInput;
(function (WebDistributionOrderByInput) {
    WebDistributionOrderByInput["AnswerLaterTriggerAsc"] = "answerLaterTrigger_ASC";
    WebDistributionOrderByInput["AnswerLaterTriggerDesc"] = "answerLaterTrigger_DESC";
    WebDistributionOrderByInput["BehaviorSettingAsc"] = "behaviorSetting_ASC";
    WebDistributionOrderByInput["BehaviorSettingDesc"] = "behaviorSetting_DESC";
    WebDistributionOrderByInput["ButtonIconAsc"] = "buttonIcon_ASC";
    WebDistributionOrderByInput["ButtonIconDesc"] = "buttonIcon_DESC";
    WebDistributionOrderByInput["ButtonLabelAsc"] = "buttonLabel_ASC";
    WebDistributionOrderByInput["ButtonLabelDesc"] = "buttonLabel_DESC";
    WebDistributionOrderByInput["ContainerAsc"] = "container_ASC";
    WebDistributionOrderByInput["ContainerDesc"] = "container_DESC";
    WebDistributionOrderByInput["ContainerTypeAsc"] = "containerType_ASC";
    WebDistributionOrderByInput["ContainerTypeDesc"] = "containerType_DESC";
    WebDistributionOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    WebDistributionOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    WebDistributionOrderByInput["CustomExpressionAsc"] = "customExpression_ASC";
    WebDistributionOrderByInput["CustomExpressionDesc"] = "customExpression_DESC";
    WebDistributionOrderByInput["DeclineFrequencyAsc"] = "declineFrequency_ASC";
    WebDistributionOrderByInput["DeclineFrequencyDesc"] = "declineFrequency_DESC";
    WebDistributionOrderByInput["DelayAsc"] = "delay_ASC";
    WebDistributionOrderByInput["DelayDesc"] = "delay_DESC";
    WebDistributionOrderByInput["DesignSettingAsc"] = "designSetting_ASC";
    WebDistributionOrderByInput["DesignSettingDesc"] = "designSetting_DESC";
    WebDistributionOrderByInput["ElementSelectorAsc"] = "elementSelector_ASC";
    WebDistributionOrderByInput["ElementSelectorDesc"] = "elementSelector_DESC";
    WebDistributionOrderByInput["EmbedPositionAsc"] = "embedPosition_ASC";
    WebDistributionOrderByInput["EmbedPositionDesc"] = "embedPosition_DESC";
    WebDistributionOrderByInput["EndDateAsc"] = "endDate_ASC";
    WebDistributionOrderByInput["EndDateDesc"] = "endDate_DESC";
    WebDistributionOrderByInput["FrequencyAsc"] = "frequency_ASC";
    WebDistributionOrderByInput["FrequencyDesc"] = "frequency_DESC";
    WebDistributionOrderByInput["IdAsc"] = "id_ASC";
    WebDistributionOrderByInput["IdDesc"] = "id_DESC";
    WebDistributionOrderByInput["IdleAsc"] = "idle_ASC";
    WebDistributionOrderByInput["IdleDesc"] = "idle_DESC";
    WebDistributionOrderByInput["IframeAsc"] = "iframe_ASC";
    WebDistributionOrderByInput["IframeDesc"] = "iframe_DESC";
    WebDistributionOrderByInput["LegacySurveyUrlAsc"] = "legacySurveyUrl_ASC";
    WebDistributionOrderByInput["LegacySurveyUrlDesc"] = "legacySurveyUrl_DESC";
    WebDistributionOrderByInput["LogoHeightAsc"] = "logoHeight_ASC";
    WebDistributionOrderByInput["LogoHeightDesc"] = "logoHeight_DESC";
    WebDistributionOrderByInput["LogoSourceAsc"] = "logoSource_ASC";
    WebDistributionOrderByInput["LogoSourceDesc"] = "logoSource_DESC";
    WebDistributionOrderByInput["LogoUrlAsc"] = "logoUrl_ASC";
    WebDistributionOrderByInput["LogoUrlDesc"] = "logoUrl_DESC";
    WebDistributionOrderByInput["PopupHeightAsc"] = "popupHeight_ASC";
    WebDistributionOrderByInput["PopupHeightDesc"] = "popupHeight_DESC";
    WebDistributionOrderByInput["PopupWidthAsc"] = "popupWidth_ASC";
    WebDistributionOrderByInput["PopupWidthDesc"] = "popupWidth_DESC";
    WebDistributionOrderByInput["PortionAsc"] = "portion_ASC";
    WebDistributionOrderByInput["PortionDesc"] = "portion_DESC";
    WebDistributionOrderByInput["QuizModeAsc"] = "quizMode_ASC";
    WebDistributionOrderByInput["QuizModeDesc"] = "quizMode_DESC";
    WebDistributionOrderByInput["SessionDelayAsc"] = "sessionDelay_ASC";
    WebDistributionOrderByInput["SessionDelayDesc"] = "sessionDelay_DESC";
    WebDistributionOrderByInput["ShowProgressAsc"] = "showProgress_ASC";
    WebDistributionOrderByInput["ShowProgressDesc"] = "showProgress_DESC";
    WebDistributionOrderByInput["StartDateAsc"] = "startDate_ASC";
    WebDistributionOrderByInput["StartDateDesc"] = "startDate_DESC";
    WebDistributionOrderByInput["ThemeAsc"] = "theme_ASC";
    WebDistributionOrderByInput["ThemeDesc"] = "theme_DESC";
    WebDistributionOrderByInput["TriggerAsc"] = "trigger_ASC";
    WebDistributionOrderByInput["TriggerDesc"] = "trigger_DESC";
    WebDistributionOrderByInput["TriggerPositionAsc"] = "triggerPosition_ASC";
    WebDistributionOrderByInput["TriggerPositionDesc"] = "triggerPosition_DESC";
    WebDistributionOrderByInput["TriggerStyleAsc"] = "triggerStyle_ASC";
    WebDistributionOrderByInput["TriggerStyleDesc"] = "triggerStyle_DESC";
    WebDistributionOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    WebDistributionOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    WebDistributionOrderByInput["UseToggleButtonsAsc"] = "useToggleButtons_ASC";
    WebDistributionOrderByInput["UseToggleButtonsDesc"] = "useToggleButtons_DESC";
    WebDistributionOrderByInput["WebsiteLanguageIdAsc"] = "websiteLanguageId_ASC";
    WebDistributionOrderByInput["WebsiteLanguageIdDesc"] = "websiteLanguageId_DESC";
})(WebDistributionOrderByInput = exports.WebDistributionOrderByInput || (exports.WebDistributionOrderByInput = {}));
var DeviceType;
(function (DeviceType) {
    DeviceType["Desktop"] = "DESKTOP";
    DeviceType["Mobile"] = "MOBILE";
    DeviceType["Tablet"] = "TABLET";
})(DeviceType = exports.DeviceType || (exports.DeviceType = {}));
var ScriptOrderByInput;
(function (ScriptOrderByInput) {
    ScriptOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ScriptOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ScriptOrderByInput["IdAsc"] = "id_ASC";
    ScriptOrderByInput["IdDesc"] = "id_DESC";
    ScriptOrderByInput["KeyAsc"] = "key_ASC";
    ScriptOrderByInput["KeyDesc"] = "key_DESC";
    ScriptOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ScriptOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ScriptOrderByInput = exports.ScriptOrderByInput || (exports.ScriptOrderByInput = {}));
var WebsiteOrderByInput;
(function (WebsiteOrderByInput) {
    WebsiteOrderByInput["CheckIntervalAsc"] = "checkInterval_ASC";
    WebsiteOrderByInput["CheckIntervalDesc"] = "checkInterval_DESC";
    WebsiteOrderByInput["CheckReportEmailAsc"] = "checkReportEmail_ASC";
    WebsiteOrderByInput["CheckReportEmailDesc"] = "checkReportEmail_DESC";
    WebsiteOrderByInput["CollectTrafficDataAsc"] = "collectTrafficData_ASC";
    WebsiteOrderByInput["CollectTrafficDataDesc"] = "collectTrafficData_DESC";
    WebsiteOrderByInput["CookiePrefixAsc"] = "cookiePrefix_ASC";
    WebsiteOrderByInput["CookiePrefixDesc"] = "cookiePrefix_DESC";
    WebsiteOrderByInput["CrashAnalyticsAsc"] = "crashAnalytics_ASC";
    WebsiteOrderByInput["CrashAnalyticsDesc"] = "crashAnalytics_DESC";
    WebsiteOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    WebsiteOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    WebsiteOrderByInput["CustomCssAsc"] = "customCSS_ASC";
    WebsiteOrderByInput["CustomCssDesc"] = "customCSS_DESC";
    WebsiteOrderByInput["DisableCookiesAsc"] = "disableCookies_ASC";
    WebsiteOrderByInput["DisableCookiesDesc"] = "disableCookies_DESC";
    WebsiteOrderByInput["FormAnalyticsAsc"] = "formAnalytics_ASC";
    WebsiteOrderByInput["FormAnalyticsDesc"] = "formAnalytics_DESC";
    WebsiteOrderByInput["IdAsc"] = "id_ASC";
    WebsiteOrderByInput["IdDesc"] = "id_DESC";
    WebsiteOrderByInput["LanguageAsc"] = "language_ASC";
    WebsiteOrderByInput["LanguageDesc"] = "language_DESC";
    WebsiteOrderByInput["LastActivityTimestampAsc"] = "lastActivityTimestamp_ASC";
    WebsiteOrderByInput["LastActivityTimestampDesc"] = "lastActivityTimestamp_DESC";
    WebsiteOrderByInput["LastActivityUrlAsc"] = "lastActivityUrl_ASC";
    WebsiteOrderByInput["LastActivityUrlDesc"] = "lastActivityUrl_DESC";
    WebsiteOrderByInput["LinkTrackingAsc"] = "linkTracking_ASC";
    WebsiteOrderByInput["LinkTrackingDesc"] = "linkTracking_DESC";
    WebsiteOrderByInput["MatomoIdAsc"] = "matomoId_ASC";
    WebsiteOrderByInput["MatomoIdDesc"] = "matomoId_DESC";
    WebsiteOrderByInput["MediaAnalyticsAsc"] = "mediaAnalytics_ASC";
    WebsiteOrderByInput["MediaAnalyticsDesc"] = "mediaAnalytics_DESC";
    WebsiteOrderByInput["RequireCookieConsentAsc"] = "requireCookieConsent_ASC";
    WebsiteOrderByInput["RequireCookieConsentDesc"] = "requireCookieConsent_DESC";
    WebsiteOrderByInput["TitleAsc"] = "title_ASC";
    WebsiteOrderByInput["TitleDesc"] = "title_DESC";
    WebsiteOrderByInput["TrackPageViewsAsc"] = "trackPageViews_ASC";
    WebsiteOrderByInput["TrackPageViewsDesc"] = "trackPageViews_DESC";
    WebsiteOrderByInput["TrackVisitorsAsc"] = "trackVisitors_ASC";
    WebsiteOrderByInput["TrackVisitorsDesc"] = "trackVisitors_DESC";
    WebsiteOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    WebsiteOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    WebsiteOrderByInput["UrlAsc"] = "url_ASC";
    WebsiteOrderByInput["UrlDesc"] = "url_DESC";
    WebsiteOrderByInput["UseCookiesAsc"] = "useCookies_ASC";
    WebsiteOrderByInput["UseCookiesDesc"] = "useCookies_DESC";
})(WebsiteOrderByInput = exports.WebsiteOrderByInput || (exports.WebsiteOrderByInput = {}));
var FilterSegmentOrderByInput;
(function (FilterSegmentOrderByInput) {
    FilterSegmentOrderByInput["ColorAsc"] = "color_ASC";
    FilterSegmentOrderByInput["ColorDesc"] = "color_DESC";
    FilterSegmentOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    FilterSegmentOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    FilterSegmentOrderByInput["CreatedByEmailAsc"] = "createdByEmail_ASC";
    FilterSegmentOrderByInput["CreatedByEmailDesc"] = "createdByEmail_DESC";
    FilterSegmentOrderByInput["FilterOptionsInvertedAsc"] = "filterOptionsInverted_ASC";
    FilterSegmentOrderByInput["FilterOptionsInvertedDesc"] = "filterOptionsInverted_DESC";
    FilterSegmentOrderByInput["FilterOptionsOperatorAsc"] = "filterOptionsOperator_ASC";
    FilterSegmentOrderByInput["FilterOptionsOperatorDesc"] = "filterOptionsOperator_DESC";
    FilterSegmentOrderByInput["FriendlyTitleAsc"] = "friendlyTitle_ASC";
    FilterSegmentOrderByInput["FriendlyTitleDesc"] = "friendlyTitle_DESC";
    FilterSegmentOrderByInput["IdAsc"] = "id_ASC";
    FilterSegmentOrderByInput["IdDesc"] = "id_DESC";
    FilterSegmentOrderByInput["IsEnabledAsc"] = "isEnabled_ASC";
    FilterSegmentOrderByInput["IsEnabledDesc"] = "isEnabled_DESC";
    FilterSegmentOrderByInput["MatomoCustomDimensionAsc"] = "matomoCustomDimension_ASC";
    FilterSegmentOrderByInput["MatomoCustomDimensionDesc"] = "matomoCustomDimension_DESC";
    FilterSegmentOrderByInput["SharedAsc"] = "shared_ASC";
    FilterSegmentOrderByInput["SharedDesc"] = "shared_DESC";
    FilterSegmentOrderByInput["SortIndexAsc"] = "sortIndex_ASC";
    FilterSegmentOrderByInput["SortIndexDesc"] = "sortIndex_DESC";
    FilterSegmentOrderByInput["SyncToMatomoAsc"] = "syncToMatomo_ASC";
    FilterSegmentOrderByInput["SyncToMatomoDesc"] = "syncToMatomo_DESC";
    FilterSegmentOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    FilterSegmentOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(FilterSegmentOrderByInput = exports.FilterSegmentOrderByInput || (exports.FilterSegmentOrderByInput = {}));
var FilterOptionOrderByInput;
(function (FilterOptionOrderByInput) {
    FilterOptionOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    FilterOptionOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    FilterOptionOrderByInput["FriendlyTitleAsc"] = "friendlyTitle_ASC";
    FilterOptionOrderByInput["FriendlyTitleDesc"] = "friendlyTitle_DESC";
    FilterOptionOrderByInput["IdAsc"] = "id_ASC";
    FilterOptionOrderByInput["IdDesc"] = "id_DESC";
    FilterOptionOrderByInput["RelationIdAsc"] = "relationId_ASC";
    FilterOptionOrderByInput["RelationIdDesc"] = "relationId_DESC";
    FilterOptionOrderByInput["TypeAsc"] = "type_ASC";
    FilterOptionOrderByInput["TypeDesc"] = "type_DESC";
    FilterOptionOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    FilterOptionOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(FilterOptionOrderByInput = exports.FilterOptionOrderByInput || (exports.FilterOptionOrderByInput = {}));
var SharedFilterSegmentOrderByInput;
(function (SharedFilterSegmentOrderByInput) {
    SharedFilterSegmentOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    SharedFilterSegmentOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    SharedFilterSegmentOrderByInput["IdAsc"] = "id_ASC";
    SharedFilterSegmentOrderByInput["IdDesc"] = "id_DESC";
    SharedFilterSegmentOrderByInput["IsEnabledAsc"] = "isEnabled_ASC";
    SharedFilterSegmentOrderByInput["IsEnabledDesc"] = "isEnabled_DESC";
    SharedFilterSegmentOrderByInput["SortIndexAsc"] = "sortIndex_ASC";
    SharedFilterSegmentOrderByInput["SortIndexDesc"] = "sortIndex_DESC";
    SharedFilterSegmentOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    SharedFilterSegmentOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(SharedFilterSegmentOrderByInput = exports.SharedFilterSegmentOrderByInput || (exports.SharedFilterSegmentOrderByInput = {}));
var SurveyResponseOrderByInput;
(function (SurveyResponseOrderByInput) {
    SurveyResponseOrderByInput["CompletedAsc"] = "completed_ASC";
    SurveyResponseOrderByInput["CompletedDesc"] = "completed_DESC";
    SurveyResponseOrderByInput["CountryCodeAsc"] = "countryCode_ASC";
    SurveyResponseOrderByInput["CountryCodeDesc"] = "countryCode_DESC";
    SurveyResponseOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    SurveyResponseOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    SurveyResponseOrderByInput["DistributionIdAsc"] = "distributionId_ASC";
    SurveyResponseOrderByInput["DistributionIdDesc"] = "distributionId_DESC";
    SurveyResponseOrderByInput["GaClientIdAsc"] = "gaClientId_ASC";
    SurveyResponseOrderByInput["GaClientIdDesc"] = "gaClientId_DESC";
    SurveyResponseOrderByInput["IdAsc"] = "id_ASC";
    SurveyResponseOrderByInput["IdDesc"] = "id_DESC";
    SurveyResponseOrderByInput["LanguageAsc"] = "language_ASC";
    SurveyResponseOrderByInput["LanguageDesc"] = "language_DESC";
    SurveyResponseOrderByInput["LegacyIdAsc"] = "legacyId_ASC";
    SurveyResponseOrderByInput["LegacyIdDesc"] = "legacyId_DESC";
    SurveyResponseOrderByInput["MatomoVisitIdAsc"] = "matomoVisitId_ASC";
    SurveyResponseOrderByInput["MatomoVisitIdDesc"] = "matomoVisitId_DESC";
    SurveyResponseOrderByInput["RespondedAtAsc"] = "respondedAt_ASC";
    SurveyResponseOrderByInput["RespondedAtDesc"] = "respondedAt_DESC";
    SurveyResponseOrderByInput["RespondentIdAsc"] = "respondentId_ASC";
    SurveyResponseOrderByInput["RespondentIdDesc"] = "respondentId_DESC";
    SurveyResponseOrderByInput["SurveyVisitIdAsc"] = "surveyVisitId_ASC";
    SurveyResponseOrderByInput["SurveyVisitIdDesc"] = "surveyVisitId_DESC";
    SurveyResponseOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    SurveyResponseOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    SurveyResponseOrderByInput["UserAgentAsc"] = "userAgent_ASC";
    SurveyResponseOrderByInput["UserAgentDesc"] = "userAgent_DESC";
    SurveyResponseOrderByInput["WebpageTitleAsc"] = "webpageTitle_ASC";
    SurveyResponseOrderByInput["WebpageTitleDesc"] = "webpageTitle_DESC";
    SurveyResponseOrderByInput["WebpageUrlAsc"] = "webpageUrl_ASC";
    SurveyResponseOrderByInput["WebpageUrlDesc"] = "webpageUrl_DESC";
})(SurveyResponseOrderByInput = exports.SurveyResponseOrderByInput || (exports.SurveyResponseOrderByInput = {}));
var QuestionResponseOrderByInput;
(function (QuestionResponseOrderByInput) {
    QuestionResponseOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    QuestionResponseOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    QuestionResponseOrderByInput["IdAsc"] = "id_ASC";
    QuestionResponseOrderByInput["IdDesc"] = "id_DESC";
    QuestionResponseOrderByInput["RedactedAtAsc"] = "redactedAt_ASC";
    QuestionResponseOrderByInput["RedactedAtDesc"] = "redactedAt_DESC";
    QuestionResponseOrderByInput["TextAsc"] = "text_ASC";
    QuestionResponseOrderByInput["TextDesc"] = "text_DESC";
    QuestionResponseOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    QuestionResponseOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    QuestionResponseOrderByInput["ValueAsc"] = "value_ASC";
    QuestionResponseOrderByInput["ValueDesc"] = "value_DESC";
})(QuestionResponseOrderByInput = exports.QuestionResponseOrderByInput || (exports.QuestionResponseOrderByInput = {}));
var AutoTaggedCommentOrderByInput;
(function (AutoTaggedCommentOrderByInput) {
    AutoTaggedCommentOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    AutoTaggedCommentOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    AutoTaggedCommentOrderByInput["IdAsc"] = "id_ASC";
    AutoTaggedCommentOrderByInput["IdDesc"] = "id_DESC";
    AutoTaggedCommentOrderByInput["TranslationAsc"] = "translation_ASC";
    AutoTaggedCommentOrderByInput["TranslationDesc"] = "translation_DESC";
    AutoTaggedCommentOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    AutoTaggedCommentOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(AutoTaggedCommentOrderByInput = exports.AutoTaggedCommentOrderByInput || (exports.AutoTaggedCommentOrderByInput = {}));
var AutoTaggingOrderByInput;
(function (AutoTaggingOrderByInput) {
    AutoTaggingOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    AutoTaggingOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    AutoTaggingOrderByInput["IdAsc"] = "id_ASC";
    AutoTaggingOrderByInput["IdDesc"] = "id_DESC";
    AutoTaggingOrderByInput["InputAsc"] = "input_ASC";
    AutoTaggingOrderByInput["InputDesc"] = "input_DESC";
    AutoTaggingOrderByInput["PromptAsc"] = "prompt_ASC";
    AutoTaggingOrderByInput["PromptDesc"] = "prompt_DESC";
    AutoTaggingOrderByInput["ResponseAsc"] = "response_ASC";
    AutoTaggingOrderByInput["ResponseDesc"] = "response_DESC";
    AutoTaggingOrderByInput["StatusAsc"] = "status_ASC";
    AutoTaggingOrderByInput["StatusDesc"] = "status_DESC";
    AutoTaggingOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    AutoTaggingOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(AutoTaggingOrderByInput = exports.AutoTaggingOrderByInput || (exports.AutoTaggingOrderByInput = {}));
var QuestionCategoryOrderByInput;
(function (QuestionCategoryOrderByInput) {
    QuestionCategoryOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    QuestionCategoryOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    QuestionCategoryOrderByInput["IdAsc"] = "id_ASC";
    QuestionCategoryOrderByInput["IdDesc"] = "id_DESC";
    QuestionCategoryOrderByInput["LegacyIdAsc"] = "legacyId_ASC";
    QuestionCategoryOrderByInput["LegacyIdDesc"] = "legacyId_DESC";
    QuestionCategoryOrderByInput["TitleAsc"] = "title_ASC";
    QuestionCategoryOrderByInput["TitleDesc"] = "title_DESC";
    QuestionCategoryOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    QuestionCategoryOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(QuestionCategoryOrderByInput = exports.QuestionCategoryOrderByInput || (exports.QuestionCategoryOrderByInput = {}));
var QuestionOrderByInput;
(function (QuestionOrderByInput) {
    QuestionOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    QuestionOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    QuestionOrderByInput["DescriptionAsc"] = "description_ASC";
    QuestionOrderByInput["DescriptionDesc"] = "description_DESC";
    QuestionOrderByInput["IdAsc"] = "id_ASC";
    QuestionOrderByInput["IdDesc"] = "id_DESC";
    QuestionOrderByInput["TitleAsc"] = "title_ASC";
    QuestionOrderByInput["TitleDesc"] = "title_DESC";
    QuestionOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    QuestionOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(QuestionOrderByInput = exports.QuestionOrderByInput || (exports.QuestionOrderByInput = {}));
var SurveyContentDependencyOrderByInput;
(function (SurveyContentDependencyOrderByInput) {
    SurveyContentDependencyOrderByInput["AnswerAsc"] = "answer_ASC";
    SurveyContentDependencyOrderByInput["AnswerDesc"] = "answer_DESC";
    SurveyContentDependencyOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    SurveyContentDependencyOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    SurveyContentDependencyOrderByInput["IdAsc"] = "id_ASC";
    SurveyContentDependencyOrderByInput["IdDesc"] = "id_DESC";
    SurveyContentDependencyOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    SurveyContentDependencyOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(SurveyContentDependencyOrderByInput = exports.SurveyContentDependencyOrderByInput || (exports.SurveyContentDependencyOrderByInput = {}));
var SurveyContentOrderByInput;
(function (SurveyContentOrderByInput) {
    SurveyContentOrderByInput["ActiveAsc"] = "active_ASC";
    SurveyContentOrderByInput["ActiveDesc"] = "active_DESC";
    SurveyContentOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    SurveyContentOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    SurveyContentOrderByInput["DependencyOperatorAsc"] = "dependencyOperator_ASC";
    SurveyContentOrderByInput["DependencyOperatorDesc"] = "dependencyOperator_DESC";
    SurveyContentOrderByInput["IdAsc"] = "id_ASC";
    SurveyContentOrderByInput["IdDesc"] = "id_DESC";
    SurveyContentOrderByInput["LegacyIdAsc"] = "legacyId_ASC";
    SurveyContentOrderByInput["LegacyIdDesc"] = "legacyId_DESC";
    SurveyContentOrderByInput["PageBreakAsc"] = "pageBreak_ASC";
    SurveyContentOrderByInput["PageBreakDesc"] = "pageBreak_DESC";
    SurveyContentOrderByInput["PositionAsc"] = "position_ASC";
    SurveyContentOrderByInput["PositionDesc"] = "position_DESC";
    SurveyContentOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    SurveyContentOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(SurveyContentOrderByInput = exports.SurveyContentOrderByInput || (exports.SurveyContentOrderByInput = {}));
var TextTranslationOrderByInput;
(function (TextTranslationOrderByInput) {
    TextTranslationOrderByInput["BodyAsc"] = "body_ASC";
    TextTranslationOrderByInput["BodyDesc"] = "body_DESC";
    TextTranslationOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    TextTranslationOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    TextTranslationOrderByInput["IdAsc"] = "id_ASC";
    TextTranslationOrderByInput["IdDesc"] = "id_DESC";
    TextTranslationOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    TextTranslationOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(TextTranslationOrderByInput = exports.TextTranslationOrderByInput || (exports.TextTranslationOrderByInput = {}));
var OptionOrderByInput;
(function (OptionOrderByInput) {
    OptionOrderByInput["ActiveAsc"] = "active_ASC";
    OptionOrderByInput["ActiveDesc"] = "active_DESC";
    OptionOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    OptionOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    OptionOrderByInput["IdAsc"] = "id_ASC";
    OptionOrderByInput["IdDesc"] = "id_DESC";
    OptionOrderByInput["LegacyIdAsc"] = "legacyId_ASC";
    OptionOrderByInput["LegacyIdDesc"] = "legacyId_DESC";
    OptionOrderByInput["PositionAsc"] = "position_ASC";
    OptionOrderByInput["PositionDesc"] = "position_DESC";
    OptionOrderByInput["TitleAsc"] = "title_ASC";
    OptionOrderByInput["TitleDesc"] = "title_DESC";
    OptionOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    OptionOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    OptionOrderByInput["ValueAsc"] = "value_ASC";
    OptionOrderByInput["ValueDesc"] = "value_DESC";
})(OptionOrderByInput = exports.OptionOrderByInput || (exports.OptionOrderByInput = {}));
var OptionCategoryOrderByInput;
(function (OptionCategoryOrderByInput) {
    OptionCategoryOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    OptionCategoryOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    OptionCategoryOrderByInput["IdAsc"] = "id_ASC";
    OptionCategoryOrderByInput["IdDesc"] = "id_DESC";
    OptionCategoryOrderByInput["LegacyTitleAsc"] = "legacyTitle_ASC";
    OptionCategoryOrderByInput["LegacyTitleDesc"] = "legacyTitle_DESC";
    OptionCategoryOrderByInput["TitleAsc"] = "title_ASC";
    OptionCategoryOrderByInput["TitleDesc"] = "title_DESC";
    OptionCategoryOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    OptionCategoryOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(OptionCategoryOrderByInput = exports.OptionCategoryOrderByInput || (exports.OptionCategoryOrderByInput = {}));
var TaggedCommentOrderByInput;
(function (TaggedCommentOrderByInput) {
    TaggedCommentOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    TaggedCommentOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    TaggedCommentOrderByInput["IdAsc"] = "id_ASC";
    TaggedCommentOrderByInput["IdDesc"] = "id_DESC";
    TaggedCommentOrderByInput["TextAsc"] = "text_ASC";
    TaggedCommentOrderByInput["TextDesc"] = "text_DESC";
    TaggedCommentOrderByInput["TypeAsc"] = "type_ASC";
    TaggedCommentOrderByInput["TypeDesc"] = "type_DESC";
    TaggedCommentOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    TaggedCommentOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(TaggedCommentOrderByInput = exports.TaggedCommentOrderByInput || (exports.TaggedCommentOrderByInput = {}));
var FlaggedCommentMetaInputOrderByInput;
(function (FlaggedCommentMetaInputOrderByInput) {
    FlaggedCommentMetaInputOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    FlaggedCommentMetaInputOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    FlaggedCommentMetaInputOrderByInput["IdAsc"] = "id_ASC";
    FlaggedCommentMetaInputOrderByInput["IdDesc"] = "id_DESC";
    FlaggedCommentMetaInputOrderByInput["TypeAsc"] = "type_ASC";
    FlaggedCommentMetaInputOrderByInput["TypeDesc"] = "type_DESC";
    FlaggedCommentMetaInputOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    FlaggedCommentMetaInputOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    FlaggedCommentMetaInputOrderByInput["ValueAsc"] = "value_ASC";
    FlaggedCommentMetaInputOrderByInput["ValueDesc"] = "value_DESC";
})(FlaggedCommentMetaInputOrderByInput = exports.FlaggedCommentMetaInputOrderByInput || (exports.FlaggedCommentMetaInputOrderByInput = {}));
var FlaggedCommentOrderByInput;
(function (FlaggedCommentOrderByInput) {
    FlaggedCommentOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    FlaggedCommentOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    FlaggedCommentOrderByInput["IdAsc"] = "id_ASC";
    FlaggedCommentOrderByInput["IdDesc"] = "id_DESC";
    FlaggedCommentOrderByInput["LanguageAsc"] = "language_ASC";
    FlaggedCommentOrderByInput["LanguageDesc"] = "language_DESC";
    FlaggedCommentOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    FlaggedCommentOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(FlaggedCommentOrderByInput = exports.FlaggedCommentOrderByInput || (exports.FlaggedCommentOrderByInput = {}));
var ReportDistributionOrderByInput;
(function (ReportDistributionOrderByInput) {
    ReportDistributionOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ReportDistributionOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ReportDistributionOrderByInput["DeliveryMethodAsc"] = "deliveryMethod_ASC";
    ReportDistributionOrderByInput["DeliveryMethodDesc"] = "deliveryMethod_DESC";
    ReportDistributionOrderByInput["EndDateTimeAsc"] = "endDateTime_ASC";
    ReportDistributionOrderByInput["EndDateTimeDesc"] = "endDateTime_DESC";
    ReportDistributionOrderByInput["FrequencyAsc"] = "frequency_ASC";
    ReportDistributionOrderByInput["FrequencyDesc"] = "frequency_DESC";
    ReportDistributionOrderByInput["IdAsc"] = "id_ASC";
    ReportDistributionOrderByInput["IdDesc"] = "id_DESC";
    ReportDistributionOrderByInput["IncludePdfAsc"] = "includePDF_ASC";
    ReportDistributionOrderByInput["IncludePdfDesc"] = "includePDF_DESC";
    ReportDistributionOrderByInput["StartDateTimeAsc"] = "startDateTime_ASC";
    ReportDistributionOrderByInput["StartDateTimeDesc"] = "startDateTime_DESC";
    ReportDistributionOrderByInput["TextResponsesAsc"] = "textResponses_ASC";
    ReportDistributionOrderByInput["TextResponsesDesc"] = "textResponses_DESC";
    ReportDistributionOrderByInput["TitleAsc"] = "title_ASC";
    ReportDistributionOrderByInput["TitleDesc"] = "title_DESC";
    ReportDistributionOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ReportDistributionOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ReportDistributionOrderByInput = exports.ReportDistributionOrderByInput || (exports.ReportDistributionOrderByInput = {}));
var ReportDeliveryOrderByInput;
(function (ReportDeliveryOrderByInput) {
    ReportDeliveryOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ReportDeliveryOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ReportDeliveryOrderByInput["DateAsc"] = "date_ASC";
    ReportDeliveryOrderByInput["DateDesc"] = "date_DESC";
    ReportDeliveryOrderByInput["IdAsc"] = "id_ASC";
    ReportDeliveryOrderByInput["IdDesc"] = "id_DESC";
    ReportDeliveryOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ReportDeliveryOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ReportDeliveryOrderByInput = exports.ReportDeliveryOrderByInput || (exports.ReportDeliveryOrderByInput = {}));
var ClientPanelOrderByInput;
(function (ClientPanelOrderByInput) {
    ClientPanelOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ClientPanelOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ClientPanelOrderByInput["EmailAsc"] = "email_ASC";
    ClientPanelOrderByInput["EmailDesc"] = "email_DESC";
    ClientPanelOrderByInput["IdAsc"] = "id_ASC";
    ClientPanelOrderByInput["IdDesc"] = "id_DESC";
    ClientPanelOrderByInput["NameAsc"] = "name_ASC";
    ClientPanelOrderByInput["NameDesc"] = "name_DESC";
    ClientPanelOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ClientPanelOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ClientPanelOrderByInput = exports.ClientPanelOrderByInput || (exports.ClientPanelOrderByInput = {}));
var DataSourceUsageOrderByInput;
(function (DataSourceUsageOrderByInput) {
    DataSourceUsageOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    DataSourceUsageOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    DataSourceUsageOrderByInput["CurrentAsc"] = "current_ASC";
    DataSourceUsageOrderByInput["CurrentDesc"] = "current_DESC";
    DataSourceUsageOrderByInput["EndDateAsc"] = "endDate_ASC";
    DataSourceUsageOrderByInput["EndDateDesc"] = "endDate_DESC";
    DataSourceUsageOrderByInput["EstimatedAsc"] = "estimated_ASC";
    DataSourceUsageOrderByInput["EstimatedDesc"] = "estimated_DESC";
    DataSourceUsageOrderByInput["IdAsc"] = "id_ASC";
    DataSourceUsageOrderByInput["IdDesc"] = "id_DESC";
    DataSourceUsageOrderByInput["LimitAsc"] = "limit_ASC";
    DataSourceUsageOrderByInput["LimitDesc"] = "limit_DESC";
    DataSourceUsageOrderByInput["PeriodAsc"] = "period_ASC";
    DataSourceUsageOrderByInput["PeriodDesc"] = "period_DESC";
    DataSourceUsageOrderByInput["SettingAsc"] = "setting_ASC";
    DataSourceUsageOrderByInput["SettingDesc"] = "setting_DESC";
    DataSourceUsageOrderByInput["StartDateAsc"] = "startDate_ASC";
    DataSourceUsageOrderByInput["StartDateDesc"] = "startDate_DESC";
    DataSourceUsageOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    DataSourceUsageOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(DataSourceUsageOrderByInput = exports.DataSourceUsageOrderByInput || (exports.DataSourceUsageOrderByInput = {}));
var DataSourceOrderByInput;
(function (DataSourceOrderByInput) {
    DataSourceOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    DataSourceOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    DataSourceOrderByInput["HubspotDealRecordIdAsc"] = "hubspotDealRecordId_ASC";
    DataSourceOrderByInput["HubspotDealRecordIdDesc"] = "hubspotDealRecordId_DESC";
    DataSourceOrderByInput["IdAsc"] = "id_ASC";
    DataSourceOrderByInput["IdDesc"] = "id_DESC";
    DataSourceOrderByInput["TitleAsc"] = "title_ASC";
    DataSourceOrderByInput["TitleDesc"] = "title_DESC";
    DataSourceOrderByInput["TypeAsc"] = "type_ASC";
    DataSourceOrderByInput["TypeDesc"] = "type_DESC";
    DataSourceOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    DataSourceOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(DataSourceOrderByInput = exports.DataSourceOrderByInput || (exports.DataSourceOrderByInput = {}));
var CustomerPrivacyPolicyOrderByInput;
(function (CustomerPrivacyPolicyOrderByInput) {
    CustomerPrivacyPolicyOrderByInput["ContentAsc"] = "content_ASC";
    CustomerPrivacyPolicyOrderByInput["ContentDesc"] = "content_DESC";
    CustomerPrivacyPolicyOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    CustomerPrivacyPolicyOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    CustomerPrivacyPolicyOrderByInput["IdAsc"] = "id_ASC";
    CustomerPrivacyPolicyOrderByInput["IdDesc"] = "id_DESC";
    CustomerPrivacyPolicyOrderByInput["LanguageAsc"] = "language_ASC";
    CustomerPrivacyPolicyOrderByInput["LanguageDesc"] = "language_DESC";
    CustomerPrivacyPolicyOrderByInput["TypeAsc"] = "type_ASC";
    CustomerPrivacyPolicyOrderByInput["TypeDesc"] = "type_DESC";
    CustomerPrivacyPolicyOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    CustomerPrivacyPolicyOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    CustomerPrivacyPolicyOrderByInput["UrlAsc"] = "url_ASC";
    CustomerPrivacyPolicyOrderByInput["UrlDesc"] = "url_DESC";
})(CustomerPrivacyPolicyOrderByInput = exports.CustomerPrivacyPolicyOrderByInput || (exports.CustomerPrivacyPolicyOrderByInput = {}));
var ReportOrderByInput;
(function (ReportOrderByInput) {
    ReportOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ReportOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ReportOrderByInput["DescriptionAsc"] = "description_ASC";
    ReportOrderByInput["DescriptionDesc"] = "description_DESC";
    ReportOrderByInput["IdAsc"] = "id_ASC";
    ReportOrderByInput["IdDesc"] = "id_DESC";
    ReportOrderByInput["LanguageAsc"] = "language_ASC";
    ReportOrderByInput["LanguageDesc"] = "language_DESC";
    ReportOrderByInput["TimeOffsetAsc"] = "timeOffset_ASC";
    ReportOrderByInput["TimeOffsetDesc"] = "timeOffset_DESC";
    ReportOrderByInput["TitleAsc"] = "title_ASC";
    ReportOrderByInput["TitleDesc"] = "title_DESC";
    ReportOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ReportOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ReportOrderByInput = exports.ReportOrderByInput || (exports.ReportOrderByInput = {}));
var BlacklistWordOrderByInput;
(function (BlacklistWordOrderByInput) {
    BlacklistWordOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    BlacklistWordOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    BlacklistWordOrderByInput["IdAsc"] = "id_ASC";
    BlacklistWordOrderByInput["IdDesc"] = "id_DESC";
    BlacklistWordOrderByInput["LanguageAsc"] = "language_ASC";
    BlacklistWordOrderByInput["LanguageDesc"] = "language_DESC";
    BlacklistWordOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    BlacklistWordOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    BlacklistWordOrderByInput["WordAsc"] = "word_ASC";
    BlacklistWordOrderByInput["WordDesc"] = "word_DESC";
})(BlacklistWordOrderByInput = exports.BlacklistWordOrderByInput || (exports.BlacklistWordOrderByInput = {}));
var BlockOrderByInput;
(function (BlockOrderByInput) {
    BlockOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    BlockOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    BlockOrderByInput["IdAsc"] = "id_ASC";
    BlockOrderByInput["IdDesc"] = "id_DESC";
    BlockOrderByInput["TitleAsc"] = "title_ASC";
    BlockOrderByInput["TitleDesc"] = "title_DESC";
    BlockOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    BlockOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(BlockOrderByInput = exports.BlockOrderByInput || (exports.BlockOrderByInput = {}));
var ChartSettingsOrderByInput;
(function (ChartSettingsOrderByInput) {
    ChartSettingsOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ChartSettingsOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ChartSettingsOrderByInput["DisplayGradingScaleAsc"] = "displayGradingScale_ASC";
    ChartSettingsOrderByInput["DisplayGradingScaleDesc"] = "displayGradingScale_DESC";
    ChartSettingsOrderByInput["DisplayModeAsc"] = "displayMode_ASC";
    ChartSettingsOrderByInput["DisplayModeDesc"] = "displayMode_DESC";
    ChartSettingsOrderByInput["FieldResultTypeAsc"] = "fieldResultType_ASC";
    ChartSettingsOrderByInput["FieldResultTypeDesc"] = "fieldResultType_DESC";
    ChartSettingsOrderByInput["IdAsc"] = "id_ASC";
    ChartSettingsOrderByInput["IdDesc"] = "id_DESC";
    ChartSettingsOrderByInput["OrderAsc"] = "order_ASC";
    ChartSettingsOrderByInput["OrderDesc"] = "order_DESC";
    ChartSettingsOrderByInput["ShowDropOffAsc"] = "showDropOff_ASC";
    ChartSettingsOrderByInput["ShowDropOffDesc"] = "showDropOff_DESC";
    ChartSettingsOrderByInput["ShowLabelsAsc"] = "showLabels_ASC";
    ChartSettingsOrderByInput["ShowLabelsDesc"] = "showLabels_DESC";
    ChartSettingsOrderByInput["ShowPreviousStepAsc"] = "showPreviousStep_ASC";
    ChartSettingsOrderByInput["ShowPreviousStepDesc"] = "showPreviousStep_DESC";
    ChartSettingsOrderByInput["SwitchedAxisAsc"] = "switchedAxis_ASC";
    ChartSettingsOrderByInput["SwitchedAxisDesc"] = "switchedAxis_DESC";
    ChartSettingsOrderByInput["TypeAsc"] = "type_ASC";
    ChartSettingsOrderByInput["TypeDesc"] = "type_DESC";
    ChartSettingsOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ChartSettingsOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    ChartSettingsOrderByInput["XAxisMarkerAsc"] = "xAxisMarker_ASC";
    ChartSettingsOrderByInput["XAxisMarkerDesc"] = "xAxisMarker_DESC";
    ChartSettingsOrderByInput["YAxisMarkerAsc"] = "yAxisMarker_ASC";
    ChartSettingsOrderByInput["YAxisMarkerDesc"] = "yAxisMarker_DESC";
})(ChartSettingsOrderByInput = exports.ChartSettingsOrderByInput || (exports.ChartSettingsOrderByInput = {}));
var ContentSettingsOrderByInput;
(function (ContentSettingsOrderByInput) {
    ContentSettingsOrderByInput["ChartCommentAsc"] = "chartComment_ASC";
    ContentSettingsOrderByInput["ChartCommentDesc"] = "chartComment_DESC";
    ContentSettingsOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ContentSettingsOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ContentSettingsOrderByInput["IdAsc"] = "id_ASC";
    ContentSettingsOrderByInput["IdDesc"] = "id_DESC";
    ContentSettingsOrderByInput["QuestionCategoryAsc"] = "questionCategory_ASC";
    ContentSettingsOrderByInput["QuestionCategoryDesc"] = "questionCategory_DESC";
    ContentSettingsOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ContentSettingsOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ContentSettingsOrderByInput = exports.ContentSettingsOrderByInput || (exports.ContentSettingsOrderByInput = {}));
var CountryOrderByInput;
(function (CountryOrderByInput) {
    CountryOrderByInput["CodeAsc"] = "code_ASC";
    CountryOrderByInput["CodeDesc"] = "code_DESC";
    CountryOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    CountryOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    CountryOrderByInput["IdAsc"] = "id_ASC";
    CountryOrderByInput["IdDesc"] = "id_DESC";
    CountryOrderByInput["NameAsc"] = "name_ASC";
    CountryOrderByInput["NameDesc"] = "name_DESC";
    CountryOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    CountryOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(CountryOrderByInput = exports.CountryOrderByInput || (exports.CountryOrderByInput = {}));
var RegionOrderByInput;
(function (RegionOrderByInput) {
    RegionOrderByInput["CodeAsc"] = "code_ASC";
    RegionOrderByInput["CodeDesc"] = "code_DESC";
    RegionOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    RegionOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    RegionOrderByInput["IdAsc"] = "id_ASC";
    RegionOrderByInput["IdDesc"] = "id_DESC";
    RegionOrderByInput["TitleAsc"] = "title_ASC";
    RegionOrderByInput["TitleDesc"] = "title_DESC";
    RegionOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    RegionOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(RegionOrderByInput = exports.RegionOrderByInput || (exports.RegionOrderByInput = {}));
var MunicipalityOrderByInput;
(function (MunicipalityOrderByInput) {
    MunicipalityOrderByInput["CodeAsc"] = "code_ASC";
    MunicipalityOrderByInput["CodeDesc"] = "code_DESC";
    MunicipalityOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    MunicipalityOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    MunicipalityOrderByInput["IdAsc"] = "id_ASC";
    MunicipalityOrderByInput["IdDesc"] = "id_DESC";
    MunicipalityOrderByInput["TitleAsc"] = "title_ASC";
    MunicipalityOrderByInput["TitleDesc"] = "title_DESC";
    MunicipalityOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    MunicipalityOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(MunicipalityOrderByInput = exports.MunicipalityOrderByInput || (exports.MunicipalityOrderByInput = {}));
var ZipOrderByInput;
(function (ZipOrderByInput) {
    ZipOrderByInput["CodeAsc"] = "code_ASC";
    ZipOrderByInput["CodeDesc"] = "code_DESC";
    ZipOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ZipOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ZipOrderByInput["IdAsc"] = "id_ASC";
    ZipOrderByInput["IdDesc"] = "id_DESC";
    ZipOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ZipOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ZipOrderByInput = exports.ZipOrderByInput || (exports.ZipOrderByInput = {}));
var CustomThemeOrderByInput;
(function (CustomThemeOrderByInput) {
    CustomThemeOrderByInput["AcceptButtonColorAsc"] = "acceptButtonColor_ASC";
    CustomThemeOrderByInput["AcceptButtonColorDesc"] = "acceptButtonColor_DESC";
    CustomThemeOrderByInput["AcceptButtonTextColorAsc"] = "acceptButtonTextColor_ASC";
    CustomThemeOrderByInput["AcceptButtonTextColorDesc"] = "acceptButtonTextColor_DESC";
    CustomThemeOrderByInput["BorderColorAsc"] = "borderColor_ASC";
    CustomThemeOrderByInput["BorderColorDesc"] = "borderColor_DESC";
    CustomThemeOrderByInput["ButtonColorAsc"] = "buttonColor_ASC";
    CustomThemeOrderByInput["ButtonColorDesc"] = "buttonColor_DESC";
    CustomThemeOrderByInput["ButtonTextColorAsc"] = "buttonTextColor_ASC";
    CustomThemeOrderByInput["ButtonTextColorDesc"] = "buttonTextColor_DESC";
    CustomThemeOrderByInput["CornerRadiusAsc"] = "cornerRadius_ASC";
    CustomThemeOrderByInput["CornerRadiusDesc"] = "cornerRadius_DESC";
    CustomThemeOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    CustomThemeOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    CustomThemeOrderByInput["CustomCssAsc"] = "customCSS_ASC";
    CustomThemeOrderByInput["CustomCssDesc"] = "customCSS_DESC";
    CustomThemeOrderByInput["DeclineButtonColorAsc"] = "declineButtonColor_ASC";
    CustomThemeOrderByInput["DeclineButtonColorDesc"] = "declineButtonColor_DESC";
    CustomThemeOrderByInput["DeclineButtonTextColorAsc"] = "declineButtonTextColor_ASC";
    CustomThemeOrderByInput["DeclineButtonTextColorDesc"] = "declineButtonTextColor_DESC";
    CustomThemeOrderByInput["DescriptionColorAsc"] = "descriptionColor_ASC";
    CustomThemeOrderByInput["DescriptionColorDesc"] = "descriptionColor_DESC";
    CustomThemeOrderByInput["DisclaimerColorAsc"] = "disclaimerColor_ASC";
    CustomThemeOrderByInput["DisclaimerColorDesc"] = "disclaimerColor_DESC";
    CustomThemeOrderByInput["HeaderColorAsc"] = "headerColor_ASC";
    CustomThemeOrderByInput["HeaderColorDesc"] = "headerColor_DESC";
    CustomThemeOrderByInput["IdAsc"] = "id_ASC";
    CustomThemeOrderByInput["IdDesc"] = "id_DESC";
    CustomThemeOrderByInput["InputBackgroundColorAsc"] = "inputBackgroundColor_ASC";
    CustomThemeOrderByInput["InputBackgroundColorDesc"] = "inputBackgroundColor_DESC";
    CustomThemeOrderByInput["InputBorderColorAsc"] = "inputBorderColor_ASC";
    CustomThemeOrderByInput["InputBorderColorDesc"] = "inputBorderColor_DESC";
    CustomThemeOrderByInput["InputErrorColorAsc"] = "inputErrorColor_ASC";
    CustomThemeOrderByInput["InputErrorColorDesc"] = "inputErrorColor_DESC";
    CustomThemeOrderByInput["InputFocusColorAsc"] = "inputFocusColor_ASC";
    CustomThemeOrderByInput["InputFocusColorDesc"] = "inputFocusColor_DESC";
    CustomThemeOrderByInput["InputSelectedBackgroundColorAsc"] = "inputSelectedBackgroundColor_ASC";
    CustomThemeOrderByInput["InputSelectedBackgroundColorDesc"] = "inputSelectedBackgroundColor_DESC";
    CustomThemeOrderByInput["InputSelectedBorderColorAsc"] = "inputSelectedBorderColor_ASC";
    CustomThemeOrderByInput["InputSelectedBorderColorDesc"] = "inputSelectedBorderColor_DESC";
    CustomThemeOrderByInput["InputSelectedTextColorAsc"] = "inputSelectedTextColor_ASC";
    CustomThemeOrderByInput["InputSelectedTextColorDesc"] = "inputSelectedTextColor_DESC";
    CustomThemeOrderByInput["InputTextColorAsc"] = "inputTextColor_ASC";
    CustomThemeOrderByInput["InputTextColorDesc"] = "inputTextColor_DESC";
    CustomThemeOrderByInput["LinkColorAsc"] = "linkColor_ASC";
    CustomThemeOrderByInput["LinkColorDesc"] = "linkColor_DESC";
    CustomThemeOrderByInput["PageBackgroundColorAsc"] = "pageBackgroundColor_ASC";
    CustomThemeOrderByInput["PageBackgroundColorDesc"] = "pageBackgroundColor_DESC";
    CustomThemeOrderByInput["PrimaryColorAsc"] = "primaryColor_ASC";
    CustomThemeOrderByInput["PrimaryColorDesc"] = "primaryColor_DESC";
    CustomThemeOrderByInput["ProgressBarBackgroundColorAsc"] = "progressBarBackgroundColor_ASC";
    CustomThemeOrderByInput["ProgressBarBackgroundColorDesc"] = "progressBarBackgroundColor_DESC";
    CustomThemeOrderByInput["ProgressBarColorAsc"] = "progressBarColor_ASC";
    CustomThemeOrderByInput["ProgressBarColorDesc"] = "progressBarColor_DESC";
    CustomThemeOrderByInput["QuestionColorAsc"] = "questionColor_ASC";
    CustomThemeOrderByInput["QuestionColorDesc"] = "questionColor_DESC";
    CustomThemeOrderByInput["SecondaryColorAsc"] = "secondaryColor_ASC";
    CustomThemeOrderByInput["SecondaryColorDesc"] = "secondaryColor_DESC";
    CustomThemeOrderByInput["SurveyBackgroundColorAsc"] = "surveyBackgroundColor_ASC";
    CustomThemeOrderByInput["SurveyBackgroundColorDesc"] = "surveyBackgroundColor_DESC";
    CustomThemeOrderByInput["TextColorAsc"] = "textColor_ASC";
    CustomThemeOrderByInput["TextColorDesc"] = "textColor_DESC";
    CustomThemeOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    CustomThemeOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(CustomThemeOrderByInput = exports.CustomThemeOrderByInput || (exports.CustomThemeOrderByInput = {}));
var CxOrderByInput;
(function (CxOrderByInput) {
    CxOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    CxOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    CxOrderByInput["IdAsc"] = "id_ASC";
    CxOrderByInput["IdDesc"] = "id_DESC";
    CxOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    CxOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(CxOrderByInput = exports.CxOrderByInput || (exports.CxOrderByInput = {}));
var FilterDateRangeOrderByInput;
(function (FilterDateRangeOrderByInput) {
    FilterDateRangeOrderByInput["ComparePeriodAsc"] = "comparePeriod_ASC";
    FilterDateRangeOrderByInput["ComparePeriodDesc"] = "comparePeriod_DESC";
    FilterDateRangeOrderByInput["CompareToPreviousAsc"] = "compareToPrevious_ASC";
    FilterDateRangeOrderByInput["CompareToPreviousDesc"] = "compareToPrevious_DESC";
    FilterDateRangeOrderByInput["CompareToPreviousValueAsc"] = "compareToPreviousValue_ASC";
    FilterDateRangeOrderByInput["CompareToPreviousValueDesc"] = "compareToPreviousValue_DESC";
    FilterDateRangeOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    FilterDateRangeOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    FilterDateRangeOrderByInput["IdAsc"] = "id_ASC";
    FilterDateRangeOrderByInput["IdDesc"] = "id_DESC";
    FilterDateRangeOrderByInput["LastNumberInputAsc"] = "lastNumberInput_ASC";
    FilterDateRangeOrderByInput["LastNumberInputDesc"] = "lastNumberInput_DESC";
    FilterDateRangeOrderByInput["PeriodAsc"] = "period_ASC";
    FilterDateRangeOrderByInput["PeriodDesc"] = "period_DESC";
    FilterDateRangeOrderByInput["PeriodTypeAsc"] = "periodType_ASC";
    FilterDateRangeOrderByInput["PeriodTypeDesc"] = "periodType_DESC";
    FilterDateRangeOrderByInput["SpecificDatesAsc"] = "specificDates_ASC";
    FilterDateRangeOrderByInput["SpecificDatesDesc"] = "specificDates_DESC";
    FilterDateRangeOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    FilterDateRangeOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(FilterDateRangeOrderByInput = exports.FilterDateRangeOrderByInput || (exports.FilterDateRangeOrderByInput = {}));
var FunnelOrderByInput;
(function (FunnelOrderByInput) {
    FunnelOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    FunnelOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    FunnelOrderByInput["IdAsc"] = "id_ASC";
    FunnelOrderByInput["IdDesc"] = "id_DESC";
    FunnelOrderByInput["TitleAsc"] = "title_ASC";
    FunnelOrderByInput["TitleDesc"] = "title_DESC";
    FunnelOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    FunnelOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(FunnelOrderByInput = exports.FunnelOrderByInput || (exports.FunnelOrderByInput = {}));
var ImageOrderByInput;
(function (ImageOrderByInput) {
    ImageOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ImageOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ImageOrderByInput["FormatAsc"] = "format_ASC";
    ImageOrderByInput["FormatDesc"] = "format_DESC";
    ImageOrderByInput["HeightAsc"] = "height_ASC";
    ImageOrderByInput["HeightDesc"] = "height_DESC";
    ImageOrderByInput["IdAsc"] = "id_ASC";
    ImageOrderByInput["IdDesc"] = "id_DESC";
    ImageOrderByInput["SizeAsc"] = "size_ASC";
    ImageOrderByInput["SizeDesc"] = "size_DESC";
    ImageOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ImageOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    ImageOrderByInput["UrlAsc"] = "url_ASC";
    ImageOrderByInput["UrlDesc"] = "url_DESC";
    ImageOrderByInput["WidthAsc"] = "width_ASC";
    ImageOrderByInput["WidthDesc"] = "width_DESC";
})(ImageOrderByInput = exports.ImageOrderByInput || (exports.ImageOrderByInput = {}));
var LicenseOrderByInput;
(function (LicenseOrderByInput) {
    LicenseOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    LicenseOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    LicenseOrderByInput["IdAsc"] = "id_ASC";
    LicenseOrderByInput["IdDesc"] = "id_DESC";
    LicenseOrderByInput["LevelAsc"] = "level_ASC";
    LicenseOrderByInput["LevelDesc"] = "level_DESC";
    LicenseOrderByInput["StatusAsc"] = "status_ASC";
    LicenseOrderByInput["StatusDesc"] = "status_DESC";
    LicenseOrderByInput["TitleAsc"] = "title_ASC";
    LicenseOrderByInput["TitleDesc"] = "title_DESC";
    LicenseOrderByInput["UniqueTitleAsc"] = "uniqueTitle_ASC";
    LicenseOrderByInput["UniqueTitleDesc"] = "uniqueTitle_DESC";
    LicenseOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    LicenseOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(LicenseOrderByInput = exports.LicenseOrderByInput || (exports.LicenseOrderByInput = {}));
var OptionGroupOrderByInput;
(function (OptionGroupOrderByInput) {
    OptionGroupOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    OptionGroupOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    OptionGroupOrderByInput["IdAsc"] = "id_ASC";
    OptionGroupOrderByInput["IdDesc"] = "id_DESC";
    OptionGroupOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    OptionGroupOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(OptionGroupOrderByInput = exports.OptionGroupOrderByInput || (exports.OptionGroupOrderByInput = {}));
var QuestionGroupOrderByInput;
(function (QuestionGroupOrderByInput) {
    QuestionGroupOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    QuestionGroupOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    QuestionGroupOrderByInput["IdAsc"] = "id_ASC";
    QuestionGroupOrderByInput["IdDesc"] = "id_DESC";
    QuestionGroupOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    QuestionGroupOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(QuestionGroupOrderByInput = exports.QuestionGroupOrderByInput || (exports.QuestionGroupOrderByInput = {}));
var QuestionSettingsOrderByInput;
(function (QuestionSettingsOrderByInput) {
    QuestionSettingsOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    QuestionSettingsOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    QuestionSettingsOrderByInput["EmailAsc"] = "email_ASC";
    QuestionSettingsOrderByInput["EmailDesc"] = "email_DESC";
    QuestionSettingsOrderByInput["IdAsc"] = "id_ASC";
    QuestionSettingsOrderByInput["IdDesc"] = "id_DESC";
    QuestionSettingsOrderByInput["LockLastOptionAsc"] = "lockLastOption_ASC";
    QuestionSettingsOrderByInput["LockLastOptionDesc"] = "lockLastOption_DESC";
    QuestionSettingsOrderByInput["MaxAsc"] = "max_ASC";
    QuestionSettingsOrderByInput["MaxDesc"] = "max_DESC";
    QuestionSettingsOrderByInput["MinAsc"] = "min_ASC";
    QuestionSettingsOrderByInput["MinDesc"] = "min_DESC";
    QuestionSettingsOrderByInput["RandomAsc"] = "random_ASC";
    QuestionSettingsOrderByInput["RandomDesc"] = "random_DESC";
    QuestionSettingsOrderByInput["RequiredAsc"] = "required_ASC";
    QuestionSettingsOrderByInput["RequiredDesc"] = "required_DESC";
    QuestionSettingsOrderByInput["RowsAsc"] = "rows_ASC";
    QuestionSettingsOrderByInput["RowsDesc"] = "rows_DESC";
    QuestionSettingsOrderByInput["SubTypeAsc"] = "subType_ASC";
    QuestionSettingsOrderByInput["SubTypeDesc"] = "subType_DESC";
    QuestionSettingsOrderByInput["TypeAsc"] = "type_ASC";
    QuestionSettingsOrderByInput["TypeDesc"] = "type_DESC";
    QuestionSettingsOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    QuestionSettingsOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(QuestionSettingsOrderByInput = exports.QuestionSettingsOrderByInput || (exports.QuestionSettingsOrderByInput = {}));
var ScoreResultsSettingsOrderByInput;
(function (ScoreResultsSettingsOrderByInput) {
    ScoreResultsSettingsOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    ScoreResultsSettingsOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    ScoreResultsSettingsOrderByInput["IdAsc"] = "id_ASC";
    ScoreResultsSettingsOrderByInput["IdDesc"] = "id_DESC";
    ScoreResultsSettingsOrderByInput["TitleAsc"] = "title_ASC";
    ScoreResultsSettingsOrderByInput["TitleDesc"] = "title_DESC";
    ScoreResultsSettingsOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    ScoreResultsSettingsOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ScoreResultsSettingsOrderByInput = exports.ScoreResultsSettingsOrderByInput || (exports.ScoreResultsSettingsOrderByInput = {}));
var SegmentType;
(function (SegmentType) {
    SegmentType["Other"] = "OTHER";
    SegmentType["SurveyRespondent"] = "SURVEY_RESPONDENT";
    SegmentType["WebsiteVisitor"] = "WEBSITE_VISITOR";
})(SegmentType = exports.SegmentType || (exports.SegmentType = {}));
var SegmentOrderByInput;
(function (SegmentOrderByInput) {
    SegmentOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    SegmentOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    SegmentOrderByInput["IdAsc"] = "id_ASC";
    SegmentOrderByInput["IdDesc"] = "id_DESC";
    SegmentOrderByInput["QueryAsc"] = "query_ASC";
    SegmentOrderByInput["QueryDesc"] = "query_DESC";
    SegmentOrderByInput["TitleAsc"] = "title_ASC";
    SegmentOrderByInput["TitleDesc"] = "title_DESC";
    SegmentOrderByInput["TypeAsc"] = "type_ASC";
    SegmentOrderByInput["TypeDesc"] = "type_DESC";
    SegmentOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    SegmentOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(SegmentOrderByInput = exports.SegmentOrderByInput || (exports.SegmentOrderByInput = {}));
var SurveySettingsOrderByInput;
(function (SurveySettingsOrderByInput) {
    SurveySettingsOrderByInput["ClientPanelAsc"] = "clientPanel_ASC";
    SurveySettingsOrderByInput["ClientPanelDesc"] = "clientPanel_DESC";
    SurveySettingsOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    SurveySettingsOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    SurveySettingsOrderByInput["HideIntroDisclaimerAsc"] = "hideIntroDisclaimer_ASC";
    SurveySettingsOrderByInput["HideIntroDisclaimerDesc"] = "hideIntroDisclaimer_DESC";
    SurveySettingsOrderByInput["IdAsc"] = "id_ASC";
    SurveySettingsOrderByInput["IdDesc"] = "id_DESC";
    SurveySettingsOrderByInput["ShowProgressAsc"] = "showProgress_ASC";
    SurveySettingsOrderByInput["ShowProgressDesc"] = "showProgress_DESC";
    SurveySettingsOrderByInput["ShowWelcomePageAsc"] = "showWelcomePage_ASC";
    SurveySettingsOrderByInput["ShowWelcomePageDesc"] = "showWelcomePage_DESC";
    SurveySettingsOrderByInput["ThankYouPageContentAsc"] = "thankYouPageContent_ASC";
    SurveySettingsOrderByInput["ThankYouPageContentDesc"] = "thankYouPageContent_DESC";
    SurveySettingsOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    SurveySettingsOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    SurveySettingsOrderByInput["WelcomePageContentAsc"] = "welcomePageContent_ASC";
    SurveySettingsOrderByInput["WelcomePageContentDesc"] = "welcomePageContent_DESC";
})(SurveySettingsOrderByInput = exports.SurveySettingsOrderByInput || (exports.SurveySettingsOrderByInput = {}));
var TextOrderByInput;
(function (TextOrderByInput) {
    TextOrderByInput["BodyAsc"] = "body_ASC";
    TextOrderByInput["BodyDesc"] = "body_DESC";
    TextOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    TextOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    TextOrderByInput["IdAsc"] = "id_ASC";
    TextOrderByInput["IdDesc"] = "id_DESC";
    TextOrderByInput["TitleAsc"] = "title_ASC";
    TextOrderByInput["TitleDesc"] = "title_DESC";
    TextOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    TextOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(TextOrderByInput = exports.TextOrderByInput || (exports.TextOrderByInput = {}));
var TranslationOrderByInput;
(function (TranslationOrderByInput) {
    TranslationOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    TranslationOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    TranslationOrderByInput["IdAsc"] = "id_ASC";
    TranslationOrderByInput["IdDesc"] = "id_DESC";
    TranslationOrderByInput["KeyAsc"] = "key_ASC";
    TranslationOrderByInput["KeyDesc"] = "key_DESC";
    TranslationOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    TranslationOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    TranslationOrderByInput["ValueAsc"] = "value_ASC";
    TranslationOrderByInput["ValueDesc"] = "value_DESC";
})(TranslationOrderByInput = exports.TranslationOrderByInput || (exports.TranslationOrderByInput = {}));
var UserOrderByInput;
(function (UserOrderByInput) {
    UserOrderByInput["Auth0IdAsc"] = "auth0Id_ASC";
    UserOrderByInput["Auth0IdDesc"] = "auth0Id_DESC";
    UserOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    UserOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    UserOrderByInput["EmailAsc"] = "email_ASC";
    UserOrderByInput["EmailDesc"] = "email_DESC";
    UserOrderByInput["FirstnameAsc"] = "firstname_ASC";
    UserOrderByInput["FirstnameDesc"] = "firstname_DESC";
    UserOrderByInput["IdAsc"] = "id_ASC";
    UserOrderByInput["IdDesc"] = "id_DESC";
    UserOrderByInput["LastLoginAsc"] = "lastLogin_ASC";
    UserOrderByInput["LastLoginDesc"] = "lastLogin_DESC";
    UserOrderByInput["LastnameAsc"] = "lastname_ASC";
    UserOrderByInput["LastnameDesc"] = "lastname_DESC";
    UserOrderByInput["LoginsCountAsc"] = "loginsCount_ASC";
    UserOrderByInput["LoginsCountDesc"] = "loginsCount_DESC";
    UserOrderByInput["MfaAsc"] = "mfa_ASC";
    UserOrderByInput["MfaDesc"] = "mfa_DESC";
    UserOrderByInput["PhoneAsc"] = "phone_ASC";
    UserOrderByInput["PhoneDesc"] = "phone_DESC";
    UserOrderByInput["ResetPasswordRequestAsc"] = "resetPasswordRequest_ASC";
    UserOrderByInput["ResetPasswordRequestDesc"] = "resetPasswordRequest_DESC";
    UserOrderByInput["StatusAsc"] = "status_ASC";
    UserOrderByInput["StatusDesc"] = "status_DESC";
    UserOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    UserOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(UserOrderByInput = exports.UserOrderByInput || (exports.UserOrderByInput = {}));
var WhitelistWordOrderByInput;
(function (WhitelistWordOrderByInput) {
    WhitelistWordOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
    WhitelistWordOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
    WhitelistWordOrderByInput["IdAsc"] = "id_ASC";
    WhitelistWordOrderByInput["IdDesc"] = "id_DESC";
    WhitelistWordOrderByInput["LanguageAsc"] = "language_ASC";
    WhitelistWordOrderByInput["LanguageDesc"] = "language_DESC";
    WhitelistWordOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
    WhitelistWordOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
    WhitelistWordOrderByInput["WordAsc"] = "word_ASC";
    WhitelistWordOrderByInput["WordDesc"] = "word_DESC";
})(WhitelistWordOrderByInput = exports.WhitelistWordOrderByInput || (exports.WhitelistWordOrderByInput = {}));
var MutationType;
(function (MutationType) {
    MutationType["Created"] = "CREATED";
    MutationType["Deleted"] = "DELETED";
    MutationType["Updated"] = "UPDATED";
})(MutationType = exports.MutationType || (exports.MutationType = {}));
